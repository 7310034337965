<!-- BCT: File Added by BCT -->
<!-- BCT: File Added by BCT -->
<!-- BCT: File Added by BCT -->
<template>
  <div>
    <hb-modal
      size="large"
      :title="title"
      v-model="dialog"
      @close="onCloseDialog"
      :footerCancelOption="true"
      show-help-link
    >
      <!-- Main content -->
      <template v-slot:content>
        <template>
          <div class="px-4 py-4 text-grey">
            Filter your spaces by the selected fields.
          </div>
          <v-col cols="12" class="pa-0">
            <v-divider></v-divider>
          </v-col>
        </template>
        <div v-for="(item, index) in content" :key="index + item.column.key">
          <hb-form :label="item.column.label">
                <!-- // Text -->
                <v-text-field
                  v-if="item.column.input === 'text'"
                  v-model="item.column.search"
                  :label="'Enter ' + item.column.label"
                  hide-details
                  single-line
                ></v-text-field>
                <!-- Text // -->

                <!-- // Multi-select -->
                <div v-else-if=" item.column.input === 'multi-select' && $store.getters['filterStore/' + item.column.key] && $store.getters['filterStore/' + item.column.key].length " >
                  <div :class="['pt-1', i === $store.getters[ 'filterStore/' + item.column.key ].length - 1 ? 'pb-1' : 'pb-2']" v-for="(data, i) in $store.getters[ 'filterStore/' + item.column.key ]" :key="`${index}-data-${i}`" >
                    <hb-checkbox
                      @change="addFilter(item.column.search, data.id)"
                      :value="item.column.search.includes(data.id)"
                      :label="data.name"
                      color="primary"
                      hide-details
                      dense
                    ></hb-checkbox>
                  </div>
                </div>

                <div v-else-if=" item.column.input === 'multi-select' && item.column.options && item.column.options.length " >
                  <div :class="['pt-1', i === item.column.options.length - 1 ? 'pb-1' : 'pb-2']" v-for="(data, i) in item.column.options" :key="`${index}-data-${i}`" >
                    <hb-checkbox
                      @change="addFilter(item.column.search, data)"
                      :value="item.column.search.includes(data)"
                      :label="data"
                      color="primary"
                      hide-details
                      dense
                    ></hb-checkbox>
                  </div>
                </div>
                <!-- Multi-select // -->

                <!-- boolean -->
                <div v-else-if="item.column.input === 'boolean'">
                  <div class="d-flex">
                    <hb-checkbox
                      v-model="item.column.search"
                      class="my-0"
                      hide-details
                      value="1"
                      label="Yes"
                      dense
                    ></hb-checkbox>
                    <hb-checkbox
                      v-model="item.column.search"
                      class="my-0 ml-4"
                      hide-details
                      value="0"
                      label="No"
                      dense
                    ></hb-checkbox>
                  </div>
                </div>

                <!-- // Date or Timeframe -->
                <div v-else-if=" item.column.input === 'timeframe' || item.column.input === 'date' " >
                  <div class="pl-0">
                    <v-select
                      :items="timeframeOptions"
                      v-model="item.column.search.label"
                      hide-details
                      dense
                      clearable
                      @click:clear="handleClear(item.column.key, 'label')"
                      background-color="white"
                      label="Select"
                      single-line
                      clear-icon="mdi-close-circle"
                    >
                    </v-select>
                  </div>

                  <div class="d-flex" v-if="item.column.search.label === 'Custom Range'" >
                    <div>
                      <hb-date-picker
                        @click:clear="handleClear(item.column.key, 'start_date')"
                        label="Start Date"
                        :clearable="true"
                        :dense="true"
                        data-vv-as="end date"
                        v-model="item.column.search.start_date"
                        class="pl-0"
                      >
                      </hb-date-picker>
                    </div>
                    <div>
                      <hb-date-picker
                        @click:clear="handleClear(item.column.key, 'end_date')"
                        label="End Date"
                        :clearable="true"
                        :dense="true"
                        data-vv-as="end date"
                        v-model="item.column.search.end_date"
                        class="pl-0"
                      >
                      </hb-date-picker>
                    </div>
                  </div>
                </div>
                <!-- timeframe // -->

                <!-- comparison -->
                <div v-else-if="item.column.input === 'comparison'">
                  <v-col cols="6" class="pl-0">
                    <v-select
                      :items="comparisonOptions"
                      v-model="item.column.search.operator"
                      hide-details
                      dense
                      @click:clear="handleClear(item.column.key, 'operator')"
                      clearable
                      background-color="white"
                      label="Select"
                      single-line
                    ></v-select>
                  </v-col>

                  <div class="d-flex">
                    <v-col cols="5" class="pl-0">
                      <v-text-field
                        v-model.lazy="item.column.search.value"
                        :prepend-inner-icon=" item.column.column_type === 'money' ? 'mdi-currency-usd' : '' "
                        name="from"
                        data-vv-scope="range"
                        v-validate="'numeric|min_value:1'"
                        :error-messages="errors.collect('range.from')"
                        :data-name="item.column.key"
                        :id="item.column.key"
                        background-color="white"
                        :aria-label="item.column.label"
                        dense
                        hide-details
                        single-line
                        :label=" item.column.search.operator && item.column.search.operator.toLowerCase() === 'between' ? 'From' : 'Value' "
                      >
                      </v-text-field>
                    </v-col>

                    <v-col cols="5" class="pl-0" v-if=" item.column.search.operator && item.column.search.operator.toLowerCase() === 'between' " >
                      <v-text-field
                        v-model.lazy="item.column.search.max"
                        :prepend-inner-icon=" item.column.column_type === 'money' ? 'mdi-currency-usd' : '' "
                        :data-name="item.column.key"
                        :id="item.column.key"
                        background-color="white"
                        :aria-label="item.column.label"
                        dense
                        label="To "
                        name="to"
                        data-vv-scope="range"
                        v-validate=" `numeric|min_value:${item.column.search.value}` "
                        :error-messages="errors.collect('range.to')"
                        hide-details
                      >
                      </v-text-field>
                    </v-col>
                  </div>
                </div>
                <!-- comparison // -->
          </hb-form>
        </div>

        <!-- // Features and Amenities column filters -->
        <div v-if="showAmenitiesFilters">
          <div class="text-center px-10 py-10" v-if="loadingAmenities">
            <v-progress-circular
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
          <div v-else>
            <div v-for="(amenities, category, index) in amenityList" :key="index" >
            <hb-form :label="category" full>
                <v-row v-for="(amenity, aIndex) in amenities" :key="aIndex">
                  <v-col :class="['pt-0', aIndex === amenities.length - 1 ? 'pb-1' : 'pb-2']">
                    <hb-checkbox
                      class="mt-0"
                      :value="amenity.selected"
                      v-model="amenity.selected"
                      hide-details
                      dense
                    >
                      <template v-slot:label>
                        <span class="hb-text-night"> {{ amenity.feature }} </span>
                      </template>
                    </hb-checkbox>
                  </v-col>
                  <v-col v-if="amenity.selected" :class="['pt-0', aIndex === amenities.length - 1 ? 'pb-0' : 'pb-1']">
                    <v-select
                      v-if="amenity.options.type === 'select'"
                      :items="amenity.options.options"
                      v-model="amenity.property_default_value"
                      item-value="id"
                      item-text="name"
                      label="Select a value"
                      :id="amenity.feature"
                      :name="amenity.feature"
                      hide-details
                      single-line
                    >
                    </v-select>
                    <hb-radio-group
                      v-else-if="amenity.options.type === 'checkbox'"
                      row
                      v-model="amenity.property_default_value"
                      :id="amenity.feature"
                      :name="amenity.feature"
                    >
                      <hb-radio value="Yes">
                        <template v-slot:label>
                          <span>Yes</span>
                        </template>
                      </hb-radio>
                      <hb-radio value="No">
                        <template v-slot:label>
                          <span>No</span>
                        </template>
                      </hb-radio>
                    </hb-radio-group>
                    <v-text-field
                      v-else
                      type="text"
                      v-model="amenity.property_default_value"
                      :label="amenity.name"
                      dense
                      class="mt-0"
                      :id="amenity.feature"
                      :name="amenity.feature"
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
            </hb-form>
            </div>
          </div>
        </div>
        <!-- Features and Amenities column filters // -->
      </template>

      <!-- Reset Filter link -->
      <template v-slot:left-actions>
        <a class="hb-link" @click="resetFilters">
          Reset Filter
        </a>
      </template>

      <!-- Apply Filter button -->
      <template v-slot:right-actions>
        <span>
          <hb-btn color="primary" type="submit" @click="applyFilters">{{
            title
          }}</hb-btn>
        </span>
      </template>
    </hb-modal>
  </div>
</template>

<script>
import HbDatePicker from "../../assets/HummingbirdDatepicker";
import api from "../../../assets/api.js";

import { mapGetters } from "vuex";
import Vue from "vue";
import { notificationMixin } from  '@/mixins/notificationMixin.js';

export default {
  name: "MasterFilter",
  components: {
    HbDatePicker,
  },
  props: ["title", "data", "columns", "reportId", "reportTemplate"],
  mixins: [notificationMixin],
  data() {
    return {
      dialog: false,
      loadingAmenities: false,
      showAmenitiesFilters: false,
      content: {},
      filterCount: 0,
      amenityList: {},
      filteredAmenities: [],
      selectedAmenitites: [],
      timeframeOptions: [
        "Today",
        "Yesterday",
        "Last 7 Days",
        "Last 30 Days",
        "This Month",
        "Last Month",
        "Year To Date",
        "Custom Range"
      ],
      comparisonOptions: [
        "Greater Than",
        "Less Than",
        "Equals",
        "Not Equals",
        "Greater Than or Equals",
        "Less Than or Equals",
        "Between"
      ]
    };
  },
  computed: {
    ...mapGetters({
      properties: "propertiesStore/filtered"
    })
  },

  async created() {
    this.dialog = true;
    this.getData(this.columns);
    if (this.showAmenitiesFilters) {
      this.getPropertyAmenities();
    }
  },

  methods: {
    getData(columns) {
      if (columns && columns.length) {
        columns.map(col => {
          if (col.key !== "unit_amenities") {
            if (!this.content[col.key]) {
              this.content[col.key] = { column: col, data: [] };
            }
          } else {
            this.selectedAmenitites = col.search;
            this.showAmenitiesFilters = true;
          }
        });
      }
    },
    addFilter(allItems, filter) {
      const idx = allItems.indexOf(filter);
      if (idx > -1) {
        allItems.splice(idx, 1)
      } else {
        allItems.push(filter)
      }
    },

    // Get all Features and Amenities for all selected properties.
    async getPropertyAmenities() {
      this.loadingAmenities = true;
      let propertyIds = this.properties.map(property => property.id);
      await api
        .get(
          this,
          api.SPACE_MANAGEMENT + `amenities?properties=${propertyIds}`
        )
        .then(res => {
          res.amenities.forEach(item => {
            {
              Vue.set(
                this.amenityList,
                item.category,
                item.amenities.map(amenity => {
                  if (
                    this.selectedAmenitites &&
                    this.selectedAmenitites.length
                  ) {
                    this.selectedAmenitites.map(a => {
                      if (
                        a.amenity_id === amenity.property_amenity_mapping_id
                      ) {
                        amenity.selected = true;
                        amenity.property_default_value = a.value;
                      }
                    });
                  }
                  amenity.options = amenity.property_options
                    ? JSON.parse(amenity.property_options)
                    : JSON.parse(amenity.options);
                  amenity.feature = amenity.property_amenity_name
                    ? amenity.property_amenity_name
                    : amenity.master_amenity_name;
                  return amenity;
                })
              );
            }
          });
          this.loadingAmenities = false;
        })
        .catch(e => {
          this.loadingAmenities = false;
          this.showMessageNotification({type: 'error', description: 'Something went wrong.!'});
        });
    },

    applyFilters() {
      this.filterCount = 0;
      this.getFilteredAmenities();
     
      for (const [key, value] of Object.entries(this.content)) {
        this.updateFilterStore(key, value.column.search);

        if (
          ["multi-select", "multi-select-amenities"].includes(
            value.column.input
          ) &&
          value.column.search.length
        ) {
          this.filterCount = this.filterCount + value.column.search.length;
        }

        if (
          ["text", "boolean"].includes(value.column.input) &&
          value.column.search
        ) {
          this.filterCount = this.filterCount + 1;
        }

        if (
          ["timeframe", "date"].includes(value.column.input) &&
          value.column.search &&
          value.column.search.label
        ) {
          this.filterCount = this.filterCount + 1;
        }

        if (
          value.column.input === "comparison" &&
          value.column.search &&
          value.column.search.operator
        ) {
          this.filterCount = this.filterCount + 1;
        }
      }

      if (this.showAmenitiesFilters) {
        this.updateFilterStore("unit_amenities", this.filteredAmenities);
        this.filterCount =
          this.filterCount +
          (this.filteredAmenities.length ? this.filteredAmenities.length : 0);
      }

      this.$emit("masterFilterEvt", this.content);
      this.showMessageNotification({type: 'success', description: this.filterCount + " Filters have been applied."});

      // close filter popup with small delay.
      setTimeout(() => {
        this.$emit("close");
      }, 300);
    },

    // Create Filtered Amenities from Amenity List
    getFilteredAmenities() {
      this.filteredAmenities = [];
      Object.keys(this.amenityList).map(key => {
        let amenities = this.amenityList[key];
        amenities.forEach(amenity => {
          if (amenity.selected) {
            this.filteredAmenities.push({
              amenity_id: amenity.property_amenity_mapping_id,
              key: amenity.feature,
              value: amenity.property_default_value
            });
          }
        });
      });
    },

    updateFilterStore(colId, filterValue) {
      this.$store.commit("reportStore/setColumnFilter", {
        report_id: this.reportId,
        report_template: this.reportTemplate,
        key: colId,
        search: filterValue
      });
    },

    onCloseDialog() {
      this.$emit("close");
    },

    resetFilters() {
      // Reset filters
      for (const [key, value] of Object.entries(this.content)) {
        value.column.search = this.init(value.column.input);
      }

      // Reset Features and Amenities filters
      if (this.showAmenitiesFilters) {
        Object.values(this.amenityList).map(amenities => {
          amenities.forEach(amenity => {
            if (amenity.selected) {
              amenity.selected = false;
            }
          });
        });
        this.filteredAmenities = [];
      }
    },

    handleClear(f, i) {},

    init(type) {
      let filter_value;
      switch (type) {
        case null:
        case "text":
        case "radio":
        case "dropdown":
          filter_value = "";
          break;
        case "multi-select":
        case "list":
        case "multi-select-amenities":
          filter_value = [];
          break;
        case "checkbox":
          filter_value = false;
          break;
        case "comparison":
          filter_value = {
            operator: "",
            value: "",
            max: ""
          };
          break;
        case "date":
        case "timeframe":
          filter_value = {
            label: "",
            days: 0,
            period: "",
            relation: "",
            start_date: "",
            end_date: ""
          };
          break;
      }
      return filter_value;
    }
  }
};
</script>

<style lang="scss" scoped>
.text-grey {
  color: grey;
}
</style>
