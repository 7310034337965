<template>
    <v-dialog v-model="dialog" width="1370" scrollable slot="body" :content-class="'hb-modal-wrapper'">
        <v-card>
            <v-card-title class="hb-modal-title d-flex align-center ma-0 pa-0">
                <v-col :cols="10" class="ma-0 py-0 px-4 d-flex align-center">
                    <span class="hb-modal-title-font-size">Assign Advanced Reservation and Rental Plan</span>
                </v-col>
                <v-col :cols="2" class="
              ma-0
              pa-0
              hb-modal-header-close-padding-right
              d-flex
              justify-end
            ">
                    <hb-btn icon tooltip="Close" @click="dialog = false" active-state-off>
                        mdi-close
                    </hb-btn>
                </v-col>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <div>
                    <span>
                        <div class="hb-modal-sub-header">
                            Select the profile you want to assign to this group from the
                            drop-down menu and choose whether or not you want the profile to
                            be active.
                        </div>
                        <v-divider></v-divider>
                    </span>
                </div>
                <v-row>
                    <v-col v-for="plan in plans" cols="6">
                        <ApwCard :isSelected="plan.id == selectedPlanId" style="height: 480px;" :plan="plan" :options="false" @click.native="selectPlan(plan.id)"></ApwCard>
                    </v-col>
                </v-row>
            </v-card-text>

            <hb-bottom-action-bar @close="close">
                <template v-slot:left-actions>
                    <help-juice />
                </template>
                <template v-slot:right-actions>
                    <slot name="right-actions"></slot><hb-btn @click="assignPlanSpaceGroup">Save</hb-btn>
                </template>
            </hb-bottom-action-bar>
        </v-card>
    </v-dialog>
</template>

<script>

import api from '../../assets/api';
import { notificationMixin } from '../../mixins/notificationMixin';
import ApwCard from './ApwCard.vue';

export default {
    name: "AssignRatePlan",
    data() {
        return {
            plans: [],
            selectedPlanId: null,
        };
    },
    components: {
        ApwCard
    },
    props:['value', 'propertyId', 'spaceGroup', 'type'],
    mixins: [notificationMixin],
    async created() {
        await this.getAllPlans();
        let plan = this.plans.find((plan) => {
            if(this.spaceGroup?.type == 'Instore'){
                return plan.name == this.spaceGroup.spacegroup_instore_apw_plan
            }else if (this.spaceGroup?.type == 'Online'){
                return plan.name == this.spaceGroup.spacegroup_online_apw_plan
            }
        })
        this.selectedPlanId = plan?.id || null
    },
    computed: {
        dialog: {
            get () {
                return this.value;
            },
            set (value) {
                this.$emit('input', value);
            }
        },
    },
    methods: {
        async getAllPlans() {
            try {
                this.plans = await api.get(this, api.APW_MANAGEMENT + 'plans')
            } catch (error) {
                console.log(error);
            }
           
            
        },
        selectPlan(id){
            this.selectedPlanId = id;
        },
        async assignPlanSpaceGroup(){
            try {
                if(this.selectedPlanId){
                    if(this.type == "bulk"){
                        this.$emit('assign-plan-bulk', this.selectedPlanId);
                        this.selectedPlanId = null;
                    }else {
                        let data = {
                            spacegroup_id: this.spaceGroup.spacegroup_id
                        };
                        if(this.spaceGroup.type === 'Instore'){
                            data.instore_apw_plan_id = this.selectedPlanId;
                        }else if(this.spaceGroup.type === 'Online'){
                            data.online_apw_plan_id = this.selectedPlanId;
                        }
                        await api.post(this, api.APW_MANAGEMENT +'properties/' + this.propertyId + '/assign-plan', data);
                        this.dialog = false;
                        this.showMessageNotification({ type: 'success', description: "Plan assigned successfully" });
                        this.selectedPlanId = null;
                        this.$emit('plan-assigned')
                    }
                }else{
                    this.showMessageNotification({ type: 'error', description: "Select a plan to before saving" });
                }
            } catch (error) {
                console.log(error);
            }
        },
        close(){
            this.dialog = false;
            this.selectedPlanId = null;
        }
    },
};
</script>

<style lang="scss" scoped>
.size-detail {
    background-color: #f4f6f8;

    .space-category {
        text-transform: capitalize;
    }
}

.hb-modal-sub-header {
    min-height: 44px;
    // padding-top: 11px;
    // padding-bottom: 14px;
    font-size: 14px;
    color: #637381;
}

.hb-modal-content {
    font-size: 14px;
    color: #101318;
}

.hb-modal-header-close-padding-right {
    padding-right: 8px !important;
}

.hb-modal-content .v-input .v-label {
    line-height: 20px;
}

.hb-modal-title-font-size {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
}

.hb-modal-title {
    min-height: 52px;
}

.v-card__actions.hb-modal-footer-wrapper {
    background: linear-gradient(180deg, #ffffff 0%, #f9fafb 100%);
    height: 60px;
    padding-left: 8px;
}

.rate-card-wrap {
    display: grid;
    height: 500px;
    overflow: auto;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 25px;

    @media (max-width: 1970px) {
        grid-template-columns: 1fr 1fr 1fr;
    }

    @media (max-width: 1470px) {
        grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 991px) {
        grid-template-columns: 1fr;
    }
}

.loader-card {
    width: 100%;
}
</style>