<template>
  <div class="generate-doc-main">
    <div style="padding: 15px 15px 0;" v-if="errorHas($options.name) || successHas($options.name)">
      <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)"
        status="error"></status>
      <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)"
        :message="successGet($options.name)" status="success"></status>
    </div>
    <div class="breadcrumb-generation">
      <hb-breadcrumb @click="backToMenu">
        <span v-if="!showConfirm" class="back-text-communication">
          Generate & Send Documents
        </span>
        <span v-if="showConfirm" class="back-text-communication">
          Recipients
        </span>
      </hb-breadcrumb>
    </div>
    <div class="generate-doc-panel-main">
      <div class="generate-doc-panel">
        <div v-if="!showConfirm" class="box-communication-first">
          <p class="mb-0">Choose the document you would like to send and add desired delivery method(s).</p>
        </div>
        <!-- normal state -->
        <hb-empty-state message="Attach or Generate Document" class="mt-4 mx-3"
          v-if="attachments.length <= 0 && !selectedDocumentId">
          <template v-slot:buttons>
            <hb-menu class="d-flex justify-center">
              <template v-slot:menu-activator>
                <hb-btn color="secondary" append-icon="mdi-menu-down">Choose Document</hb-btn>
              </template>
              <v-list>
                <v-list-item v-for="option in options" :key="option.key" @click="choseDocument(option.key)"
                  :ripple="false">
                  <v-list-item-title>{{ option.value }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </hb-menu>
          </template>
        </hb-empty-state>
        <!-- normal state ends -->

        <!-- upload section start -->
        <div v-if="attachments.length > 0" v-show="!showConfirm" class="attachment-doc-main">
          <div class="selected-doc">
            <v-row>
              <v-col class="d-flex flex-column justify-center align-center">
                <span class="hb-font-header-3-medium"> Document Attached</span>
                <hb-chip class="mt-3 chip-class" :style="{ maxwidth: `${attachmentsName.length * 10}px` }"
                  v-if="attachmentsName">
                  {{ attachmentsName }}
                </hb-chip>
              </v-col>
              <v-col class="d-flex justify-center align-center">
                <hb-menu class="d-flex justify-center" align-right>
                  <template v-slot:menu-activator>
                    <hb-btn color="secondary" append-icon="mdi-menu-down">Changes Document</hb-btn>
                  </template>
                  <v-list>
                    <v-list-item v-for="option in options" @click="choseDocument(option.key)" :ripple="false">
                      <v-list-item-title>{{ option.value }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </hb-menu>
              </v-col>
            </v-row>
          </div>
        </div>
        <!-- upload section end -->

        <!-- generate do section start -->
        <div v-if="selectedDocumentId" v-show="!showConfirm" class="attachment-doc-main">
          <div class="selected-doc">
            <v-row>
              <v-col class="d-flex flex-column justify-center align-center">
                <span class="hb-font-header-3-medium"> Document Attached</span>
                <hb-chip class="mt-3 chip-class" v-if="selectedDocument">
                  {{ selectedDocument.name }}
                </hb-chip>
              </v-col>
              <v-col class="d-flex justify-center align-center">
                <hb-menu class="d-flex justify-center" align-right>
                  <template v-slot:menu-activator>
                    <hb-btn color="secondary" append-icon="mdi-menu-down">Changes Document</hb-btn>
                  </template>
                  <v-list>
                    <v-list-item v-for="option in options" @click="choseDocument(option.key)" :ripple="false">
                      <v-list-item-title>{{ option.value }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </hb-menu>
              </v-col>
            </v-row>
          </div>
        </div>
        <!-- generate do section end -->
        <v-divider v-show="showConfirm"></v-divider>
        <p v-if="showConfirm" class="pa-4 mb-0">This message will be sent to ALL the recipients you selected below.
          Please confirm and send your message to the following tenants.</p>
        <v-container class="pa-4" v-show="showConfirm">
          <hb-data-table :loading="!contacts.length > 0" fixed-header :height="computedTableHeight" disable-sort :headers="headers" :items="contacts"
            style="overflow-y: auto;">
            <template v-slot:[`item.email`]="{ item }">
              <span v-if="item.email">{{ item.email }}</span>
              <v-alert v-else style="font-size: 14px;" dense flat text class="ma-0" type="error">
                Email address missing!
              </v-alert>
            </template>
            <template v-slot:[`item.first`]="{ item }">
              {{ item.first }} {{ item.last }}
            </template>
            <template v-slot:[`item.phone`]="{ item }">
              <span v-if="item.Phone && deliveryMethods.some(method => method.delivery_method === 'text_message')">
                {{ item.Phone.phone }}
              </span>
              <v-alert v-else style="font-size: 14px;" dense flat text class="ma-0" type="error">
                Phone Number missing!
              </v-alert>
            </template>
            <template v-slot:footer>
             <div class="pa-2 custom-footer">
              Total Recipients: {{ contacts.length}}
             </div>
            </template>
          </hb-data-table>
        </v-container>

        <!-- common section  -->
        <div class="multiple-delivery-container" v-if="shouldShowDiv" v-show="!showConfirm">
          <!-- Loop through the deliveryMethods array -->
          <div v-for="(method, index) in deliveryMethods" :key="index" class="single-delivery ">
            <div class="d-flex justify-space-between">
              <HbSelect v-model="method.delivery_method" :items="availableDeliveryMethods(index)" item-text="name"
                item-value="gds_key" data-vv-scope="default" :data-vv-name="`delivery_method${index}`"
                data-vv-as="Delivery Method" :error="errors.has(`default.delivery_method${index}`)"
                v-validate="'required'" placeholder="Choose Delivery Method" style="width: 75%;"
                @change="handleDeliveryMethodChange(method, index)" />
              <HbBtn icon tooltip="Clear" mdi-code="mdi-close-circle" @click="clearMethod(index)" />
            </div>
            <div v-if="method.delivery_method == 'standard_email'">
              <HbAutocomplete v-model="method.templateName" :items="templateNamesEmail" item-text='name'
                item-value='template_id' data-vv-scope="default" data-vv-name="autocomplete-box"
                data-vv-as="Autocomplete Box" :error="errors.has('default.autocomplete-box')"
                placeholder="Select Template" class="mt-2" single-line
                @change="appendtemplate($event, index, 'standard_email')"
                @click:clear="clearTemplate(index, 'email')" />
              <HbTextField v-model="method.subject" class="mt-3" v-validate="'required|max:45'" data-vv-scope="default"
                :data-vv-name="`subject${index}`" data-vv-as="Subject" :error="errors.has(`default.subject${index}`)"
                placeholder="Subject" />
              <rich-text-editor class="mt-3" style="border: 1px solid #8080801c;" v-model="method.email"
                proseHeight="100px" />
            </div>
            <div v-if="method.delivery_method == 'text_message'">
              <HbAutocomplete v-model="method.templateName" :items="templateNames" item-text='name'
                item-value='template_id' data-vv-scope="default" data-vv-name="autocomplete-box"
                data-vv-as="Autocomplete Box" :error="errors.has('default.autocomplete-box')"
                placeholder="Select Template" class="mt-2" single-line
                @change="appendtemplate($event, index, 'text_message')" @click:clear="clearTemplate(index, 'sms')" />
              <HbTextarea v-model="method.message" no-resize rows="5" row-height="30" class="mt-3"
                v-validate="'required|max:1000'" data-vv-scope="default" :data-vv-name="`message${index}`"
                data-vv-as="Message" :error="errors.has(`default.message${index}`)" placeholder="Enter Message"
                placeholder-fix />
            </div>
          </div>
          <div class="d-flex justify-space-between pa-3">
            <hb-link  v-if="availableDeliveryMethods().length > 0" @click="AddDeliveryMethod">+ Add
              Delivery
              Method</hb-link>
              <span v-else  style="visibility: hidden;">+ Add Delivery Method</span>
            <div>
              <hb-link v-show="showMergeFields !== true" @click="showMergeFields = true">
                Show Available Merge Fields
                <hb-tooltip>

                  <template v-slot:body>
                    Use Merge Fields in your message to insert
                    <br />data about your tenants. By using Merge
                    <br />Fields, you can send automated messages
                    <br />to your tenants without having to manually
                    <br />enter their information every time. Merge
                    <br />Fields appear in brackets.
                    <br /><br />
                    In order to use a merge field simply copy
                    <br />and paste the text in brackets and add into
                    <br />your message.
                    <br /><br />
                    Example: Hello [Tenant.FirstName] [Tenant.LastName],
                    <br />you owe [Tenant.TotalPastDue]. Please pay by
                    <br />[Tenant.RentDueDate].
                  </template>
                </hb-tooltip>
              </hb-link>

              <hb-link v-show="showMergeFields === true" @click="showMergeFields = false">
                Hide Available Merge Fields
              </hb-link>
            </div>
          </div>

        </div>
        <!-- common section  -->
        <v-row class="mr-2 ml-3" v-if="shouldShowDiv" v-show="!showConfirm">
          <merge-fields class="merge-field-custom" v-if="showMergeFields === true" />
        </v-row>
      </div>

      <div class="footer-action-bar">
        <hb-bottom-action-bar cancel-off v-if="selectedDocumentId || attachments.length > 0" v-show="!showConfirm">
          <template v-slot:right-actions>
            <hb-link @click="backToMenu">Cancel</hb-link>
            <hb-btn class="ml-4" color="primary" @click="goToNext()">
              Next
            </hb-btn>
          </template>
        </hb-bottom-action-bar>
        <hb-bottom-action-bar v-if="showConfirm" @close="showConfirm = false">
          <template v-slot:right-actions>
            <hb-btn color="primary" :loading="confirmLoading" @click="confirm">
              Confirm
            </hb-btn>
          </template>
        </hb-bottom-action-bar>
      </div>
    </div>


    <!-- upload component -->
    <add-file :show="showfile" :onClose='onFileClose' :getAttachments="getAttachments" :allowMultiple="false" :clearData="clearfiles">
    </add-file>
    <!-- upload component -->


    <!-- generate modal -->
    <hb-modal size="large" title="Browse Files" v-model="openUploadDocsStatus" @close="openUploadDocsStatus = false">
      <template v-slot:content>
        <v-app style="max-height: 699px; overflow-y: hidden;">
          <v-navigation-drawer v-model="openUploadDocsStatus" class="inside-card-drawer" absolute disable-resize-watcher
            width="200px" color=#F9FAFB>
            <v-list>
              <v-list-item v-for="(option, index) in optionsDoc" :key="index" @click="selectedOption = index"
                :class="{ 'selected': selectedOption === index }">
                <v-list-item-content>
                  <v-list-item-title style="font-size: 15px; display: flex; flex-direction: column;"><span>{{
                      option.label }}</span><span v-if="option.subLabel">{{ option.subLabel
                      }}</span></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-navigation-drawer>
          <v-main style="padding: 0 0 0 200px;" class="main-doc-wrapper">
            <div v-if="selectedOption == 0">
              <upload-bulk-unsigned-doc @selected-document-id="addDocumentId"></upload-bulk-unsigned-doc>
            </div>
            <div v-if="selectedOption == 1">
              <upload-bulk-signed-doc @selected-document-id="addDocumentId"></upload-bulk-signed-doc>
            </div>
          </v-main>
        </v-app>
      </template>
      <template v-slot:right-actions>
        <hb-btn :disabled="!selectedDocument?.id" @click="attachdoc()">Attach</hb-btn>
      </template>
    </hb-modal>
    <!-- generate modal -->

  </div>
</template>
<script>
import Status from '../Messages.vue';
import AddFile from '../../communication_center/AddFile.vue';
import Loader from '../../assets/CircleSpinner.vue';
import { mapGetters, mapActions } from 'vuex';
import api from '../../../assets/api';

import UploadBulkUnsignedDoc from './UploadBulkUnsignedDoc.vue';
import UploadBulkSignedDoc from './UploadBulkSignedDoc.vue';

import SendEmail from './SendEmail.vue';
import SendSms from './SendSms.vue';

import MergeFields from "../../includes/MergeFields";

import RichTextEditor from '../../assets/RichTextEditor.vue';
import { notificationMixin } from '../../../mixins/notificationMixin';


import { EventBus } from "../../../EventBus.js";
import contacts from '../../../assets/api/contacts.js';

export default {
  name: "BulkGenerateDocument",
  mixins: [notificationMixin],
  data() {
    return {
      headers: [
        { text: 'Person', value: 'first' },
        { text: 'Email',  value: 'email' },
        {text :'Phone', value:'Phone.phone'} 
      ],
      showMergeFields: false,

      temmplateName: "",
      templateNames: [],
      templateNamesEmail: [],


      confirmLoading: false,
      loadingContacts: false,

      options: [
        {
          key: "attach_document",
          value: "Attach Document"
        },
        {
          key: "generate_document_non_signature",
          value: "Generate Document (Non-Signature)"
        },
        {
          key: "generate_document_signature",
          value: "Generate Document (Signature)"
        }
      ],

      showConfirm: false,
      contacts: [],
      filteredList: [],

      attachments: [],
      existingDoc: "",
      attachmentsName: '',
      showfile: false,

      // Generate Documents
      openUploadDocsStatus: false,
      selectedOption: 0,
      optionsDoc: [
        { label: 'Generate Documents', subLabel: ' (Non-Signature)' },
        { label: 'Generate Documents', subLabel: ' (Signature)' },
      ],
      selectedDocument: null,
      selectedDocumentId: null,

      deliveryMethod: '',
      deliveryMethods: [{
        templateName: '',
        delivery_method: '',
        email: '',
        message: ''
      }],
      deliveryMethodsList: [],
      showEditForm: false,
      show_dialog: false,
      units: [],
      form: {
      },
      clearfiles: false
    }
  },
  props: ['items'],
  async mounted() {
  },
  components: {
    Status,
    Loader,
    AddFile,
    UploadBulkSignedDoc,
    UploadBulkUnsignedDoc,
    SendEmail,
    'rich-text-editor': RichTextEditor,
    MergeFields
  },
  mounted() {
    this.getBaseTemplateData()
  },
  computed: {
    ...mapGetters({
      facilityList: "propertiesStore/filtered",
      contactsList: "reportStore/getBulkContactsList"
    }),
    shouldShowDiv() {
      return this.selectedDocumentId || this.attachments.length > 0;
    },
    ...mapGetters({
      bulk_edit_items: 'bulkEditStore/items',
      getDeliveryMethods: 'documentsStore/getDeliveryMethods',
      property: 'propertiesStore/filtered',
    }),
    computedTableHeight() {
      const maxHeight = 400; // Define a maximum height for the table
      const rowHeight = 48;  // Approximate height of each row in pixels
      const headerHeight = 48; // Approximate height of the table header

      // Calculate height based on the number of rows plus the header height
      const calculatedHeight = (this.contacts.length * rowHeight) + headerHeight;

      // Ensure height does not exceed the maximum
      return `${Math.min(calculatedHeight, maxHeight)}px`;
    }
  },
  watch: {
    shouldShowDiv(newValue) {
      if (newValue) {
        this.fetchLocalDeliveryMethods();
      }
    }
  },
  methods: {
    ...mapActions({
      resetAction: 'bulkEditStore/resetAction',
      fetchDeliveryMethods: 'documentsStore/fetchDeliveryMethods'
    }),

    // template manager code starts
    clearTemplate(index, method) {
      if (method == 'message') this.deliveryMethods[index].message = '';
      else {
        this.deliveryMethods[index].email = ''
        this.deliveryMethods[index].subject = ''
      }
    },
    async getBaseTemplateData() {
      const templates = await this.getAllTemplatesWithId([this.property[0].id]); // only one property will select at a time
      const templateFilterd = templates.filter(template => template.base_template_id === null)
      this.templateNames = templateFilterd.filter(template => template.type === 'sms').map(template => ({ name: template.name, template_id: template.template_id }));
      this.templateNamesEmail = templateFilterd.filter(template => template.type === 'email').map(template => ({ name: template.name, template_id: template.template_id }));
    },
    async getAllTemplatesWithId(properties) {
      return await this.getTemplatesById(properties);
    },
    async getTemplatesById(propertyIds) {
      try {
        const propertyIdQueryString = propertyIds.map(id => `property_id=${id}`).join('&');
        const response = await api.get(this, api.TEMPLATE_MANAGER + `templates?${propertyIdQueryString}&get_only_active_templates=true`);
        return response.templates;
      } catch (error) {
        console.error(`Error fetching templates for property IDs ${propertyIds.join(',')}:`, error);
        throw error; // Rethrow the error to propagate it up
      }
    },
    async appendtemplate(id, index, method) {
      try {
        await api.get(this, api.TEMPLATE_MANAGER + `template/${id}`).then((res) => {
          if (method == 'standard_email') {
            if (res.template.body && res.template.subject) {
              this.deliveryMethods[index].email = res.template.body
              this.deliveryMethods[index].subject = res.template.subject
            }
            else {
              this.showMessageNotification({ type: 'error', description: "Can't Attach SMS Template to Email Field" });
            }
          }
          else {
            if (res.template.smsContent) {
              this.deliveryMethods[index].message = res.template.smsContent
            }
            else {
              this.showMessageNotification({ type: 'error', description: "Can't Attach Email Template to SMS Field" });
            }
          }

        })
      } catch (error) {
        throw error;
      }
    },
    // template manager code ends
    handleDeliveryMethodChange(method, index) {
      const matchingMethod = this.deliveryMethodsList.find(
        (item) => item.gds_key === method.delivery_method);
      const deliveryMethodId = matchingMethod ? matchingMethod.id : null;
      this.$set(this.deliveryMethods[index], 'delivery_method_id', deliveryMethodId)
      this.$set(this.deliveryMethods[index], 'property_id', this.facilityList[0].id)
    },
    goToNext() {
      this.$validator.validateAll('default').then(async (result) => {
        if (result) {
          // All fields are valid, proceed to the next step
          this.showConfirm = true
          await this.getContactDetails();
        } else {
          this.showMessageNotification({ type: 'error', description: 'There are errors in your form, correct them before continuing.', list: this.errors.items });
          return
        }
      });
    },
    async upload(contacts) {
      let body = {
        document_type: this.type,
        document_type_id: this.document_type_id,
        contacts: contacts
      }
      let response = await api.postFile(this, api.CONTACTS + '/uploads', body, this.attachments);
      return response
    },
    async confirm() {
      try {
        this.confirmLoading = true
        let form = {
          attachments: this.attachments,
          document: this.selectedDocumentId,
          data: JSON.parse(JSON.stringify(this.deliveryMethods)),
          type: this.selectedDocumentId ? this.selectedDocument.type : "",
          upload_id: null,
        };

        if (this.attachments.length) {
          let fileUploadResponse = await this.upload(form.contacts);
          form.upload_id = fileUploadResponse.uploads.id;
          form.attachments[0].content_type = fileUploadResponse.uploads.mimetype;
        }

        // Adding contacts arrays inside each delivery method in form.data
        form.data.forEach((method) => {
          method.contacts = [...this.filteredList]; // Add the contacts array to each method (backend dev requested)
          delete method.templateName; // Explicitly remove templateName from each method
        });
        // API call inside try block
        api.post(this, api.MULTIPLE_DELIVERY, form).then(r => {
          EventBus.$emit('unit_edited');
          this.confirmLoading = false
          this.$emit('close-bulk-edit');
          this.showMessageNotification({ type: 'success', description: 'Message Sent!' });
          return
        });
      } catch (error) {
        // Error handling
        this.confirmLoading = false
        console.error("Error during API request:", error);
      }
    },
    async getContactDetails() {
      let items = this.items.map(item => {
        return {
          id: item.tenant_id || item.contact_id || item.lead_id,
          lease_id: item.lease_id,
        }
      });
      let contacts_data = this.contactsList.filter(data => data.id);
      let response = await api.post(this, api.CONTACTS + 'bulk-config', { contact_ids: contacts_data.length ? contacts_data : items.filter(i => i.id) });
      this.contacts = response.contacts.map(contact => {
        let c = {
          id: contact.id,
          first: contact.first,
          last: contact.last,
          email: contact.email
        }
        c.Phone = contact.Phones.find(p => p.sms)
        let data = this.contactsList.length ? this.contactsList : items;
        let item = data.find(item => {
          if (c.id === item.id) {
            return item.lease_id
          }
        })
        c.lease_id = item.lease_id;

        return c
      });
      this.filteredList = items;
    },
    availableDeliveryMethods(index) {
      // Get the currently selected delivery methods (excluding the current one)
      const selectedMethods = this.deliveryMethods
        .filter((_, i) => i !== index)
        .map(method => method.delivery_method);

      // Return only methods that haven't been selected yet
      return this.deliveryMethodsList.filter(
        method => !selectedMethods.includes(method.gds_key)
      );
    },
    async fetchLocalDeliveryMethods() {
      if (this.selectedDocumentId || this.attachments.length > 0) {
        await this.fetchDeliveryMethods(this.property[0].id);
        let fetchedMethods = await this.getDeliveryMethods;

        // Standard delivery methods
        const standardMethods = [
          {
            name: "Standard Email",
            gds_key: "standard_email",
            delivery_method: "standard_email"
          },
          {
            name: "Text Message",
            gds_key: "text_message",
            delivery_method: "sms"
          },
        ];


        // Filter out standard methods from fetched methods
        fetchedMethods = fetchedMethods.filter(
          method => !standardMethods.some(
            standardMethod => standardMethod.delivery_method === method.gds_key
          )
        );
        if (this.attachments.length) {
          this.deliveryMethodsList = [...standardMethods];
        }
        if (this.selectedDocument?.type === 'unsigned') { //removed sms
          const filteredStandardMethods = standardMethods.filter(method => method.delivery_method !== 'sms');
          this.deliveryMethodsList = [...filteredStandardMethods, ...fetchedMethods];
        }
        if (this.selectedDocument?.type === 'signed') { //only show normal Email
          const filteredStandardMethods = standardMethods.filter(method => method.delivery_method !== 'sms');
          this.deliveryMethodsList = [...filteredStandardMethods];
        }
      }
    },
    clearMethod(i) {
      if (this.deliveryMethods.length > 1) {
        this.deliveryMethods.splice(i, 1);
        // this.deliveryMethods[i].delivery_method = null;
      } else {
        // Optional: Show a message or notification that at least one delivery method is required
      }
    },
    AddDeliveryMethod() {
      this.deliveryMethods.push({
        temmplateName: '',
        delivery_method: '',
        email: '',
        subject: '',
        message: ''
      })
    },
    choseDocument(key) {
      if (!this.items.length) {
        this.showMessageNotification({ type: 'error', description: 'Please select some records to edit.' });
        return;
      }
      EventBus.$emit('disablecheckbox:report', true);
      switch (key) {
        case 'attach_document':
          //this.removeattachment() // clear old selected attachment
          this.showfile = true
          break;
        case 'generate_document_non_signature':
            this.selectedDocumentId = null,
            this.openUploadDocsStatus = true
            this.selectedOption = 0
          break;
        case 'generate_document_signature':
          if (this.selectedDocument?.type === 'signed') {
            this.selectedDocumentId = null
          }
          this.openUploadDocsStatus = true
          this.selectedOption = 1
          break;
        default:
          return
      }
    },
    attachdoc() {
      this.removeattachment()
      this.selectedDocumentId = this.selectedDocument?.id
      this.openUploadDocsStatus = false;
    },
    addDocumentId(data) {
      this.selectedDocument = data;
    },
    getAttachments(attachments) {
      this.selectedDocument = []
      this.selectedDocumentId = null, // clearing old generated doc
      this.removeattachment()
      this.attachments = attachments;
      this.getAttachmentNames()
      this.clearfiles = true
      this.onFileClose();
    },
    removeattachment() {
      this.attachments = []
      this.existingDoc = ""
    },
    getAttachmentNames() {
      this.attachmentsName = '';
      if (this.attachments.length == 1) {
        this.attachmentsName = this.attachments[0].name;
      } else if (this.attachments.length > 1) {
        for (let i = 0; i < this.attachments.length; i++) {
          this.attachmentsName = 'Files (' + this.attachments.length + ')'
        }
      }
    },
    backToMenu() {
      if (this.showConfirm) {
        // means its from last list page
        this.showConfirm = false
      } else {
        EventBus.$emit('disablecheckbox:report', false);
        this.$emit('back')
        this.removeattachment()
      }
    },
    onFileClose() {
      this.showfile = false
    },
    closeWindow() {
      this.resetAction();
    },
    async save() {
      // let data = {
      //     unit_ids: this.items,
      //     form: {}
      // };
      // data.form[this.key] = this.form[this.key];
      // let response = await api.put(this, api.UNITS + 'bulk-edit', data );
      // EventBus.$emit('unit_edited');
    },
  }
}
</script>
<style scoped lang="scss">
.generate-doc-main {
  height: 100%;

  .generate-doc-panel-main {
    display: flex;
    flex-direction: column;
    height: 100%;
    background: var(--Primary-Grayscale-White, #FFF);

    .generate-doc-panel {
      flex: 1;
      // overflow-y: auto;
    }

    .footer-action-bar {
      height: 60px;
      position: sticky;
      bottom: 0;
      width: 100%;
    }
  }

  .breadcrumb-generation ::v-deep a.hb-link i.mdi-chevron-left {
    color: #101318 !important;
  }

  .breadcrumb-generation {
    padding: 16px;
    background: var(--Primary-Grayscale-White, #FFF);
  }

  .back-text-communication {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    color: #101318;
    display: block;
  }
}

.box-communication-first {
  border-top: 1px solid rgba(221, 223, 224, 0.575);
  border-bottom: 1px solid rgba(221, 223, 224, 0.575);
  border-left: none;
  border-right: none;
  padding: 16px;
}

.attachment-doc-main {
  padding-left: 16px !important;
  padding-right: 16px !important;
  padding-top: 16px !important;
  padding-bottom: 0px;

  .selected-doc {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .chip-class {
    ::v-deep .hb-chip-overflow {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 150px;
    }
  }
}



.multiple-delivery-container {
  padding-left: 16px !important;
  padding-right: 16px !important;
  padding-top: 0px !important;
  padding-bottom: 16px !important;
  background: var(--Primary-Grayscale-White, #FFF);

  .single-delivery {
    padding: 20px;
    margin: 12px;
    border-top: 1px solid rgba(221, 223, 224, 0.575);
    border-bottom: 1px solid rgba(221, 223, 224, 0.575);
    border-left: 1px solid rgba(221, 223, 224, 0.575);
    border-right: 1px solid rgba(221, 223, 224, 0.575);
  }

}

.merge-field-custom {
  margin-right: 0px !important;
  background: var(--Primary-Grayscale-White, #FFF) !important;
}

.multiple-delivery-container::-webkit-scrollbar {
  width: 4px;
  /* Set the width of the scrollbar */
  /* Firefox scrollbar styling */
  scrollbar-width: thin;
  /* Makes the scrollbar thin */
  scrollbar-color: #888 #f1f1f1;
  /* Thumb and track colors */
}

.multiple-delivery-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* You can set a background color */
}

.multiple-delivery-container::-webkit-scrollbar-thumb {
  background: #888;
  /* Change the color of the scrollbar handle */
  border-radius: 10px;
  /* Make the handle rounded */
}

/* Handle on hover */
.multiple-delivery-container::-webkit-scrollbar-thumb:hover {
  background: #555;
  /* Change color on hover */
}

.selected {
  /* background-color: rgba(0, 0, 255, 0.1); Highlight selected option */
  background: #e0f5f5;
  border-left: 4px solid #00848E;
  width: 100%
}

.v-card-class-main {
  margin-top: 30px;
  border-radius: 4px;
  border: 1px solid #e3e8ed;
  box-shadow: none !important;
}
.custom-footer{
  border: 1px solid rgb(223,227,232);
  font-weight: 500;
}
</style>
