<template>
  <v-dialog  v-model="show_dialog" persistent max-width="900px">
    <v-card>
      <v-btn color="primary" @click="closeWindow">Close</v-btn>
      <v-card-title>
        <span class="headline">Change Rent</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="4">
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
    import api from '../../../assets/api.js';
    import Status from '../Messages.vue';
    import Loader from '../../assets/CircleSpinner.vue';
    import HbDatePicker from '../../assets/HummingbirdDatepicker';
    import { EventBus } from '../../../EventBus.js';
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: "BulkRaiseRent",
        data() {
            return {
                showEditForm: false,
                show_dialog: false,
                units: [],
                form: {
                }
            }
        },
        props: [],
        created(){
            this.items = this.bulk_edit_items.map(item => item.unit_id);
            //this.getUnitDetails();
            this.show_dialog = true;
        },
        components: {
            Status,
            Loader
        },
        computed:{
            ...mapGetters({
                bulk_edit_items: 'bulkEditStore/items'
            })
        },
        methods: {
            ...mapActions({
                resetAction: 'bulkEditStore/resetAction'
            }),
            closeWindow() {
                this.resetAction();
            },
            async save(){
                // let data = {
                //     unit_ids: this.items,
                //     form: {}
                // };
                // data.form[this.key] = this.form[this.key];
                // let response = await api.put(this, api.UNITS + 'bulk-edit', data );
                // EventBus.$emit('unit_edited');
            },
        }
    }
</script>
