<template>
  <div class="editor-container">

    <v-card flat style="height: 100%; overflow: scroll;">
      <div>
        <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
        <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)" :message="successGet($options.name)" status="success"></status>
        <p class="font-regular mt-2 px-5" >Set which columns you would like to see in this report.</p>
      </div>

      <v-container class="hb-table" >



        <v-row class="hb-table-row" v-for="section in sections" :key="section">
          <v-col class="hb-table-label pl-6 pt-5 text-capitalize" cols="4" v-if="section === 'facility'">
            Property
          </v-col>

          <v-col class="hb-table-label pl-6 pt-5 text-capitalize" cols="4" v-else>
            {{ section | capitalize | removeUnderscore }}
          </v-col>

          <v-col class="hb-table-value">
            <v-autocomplete
              :items="getSectionTypes(section)"
              v-model="set_columns"
              item-text="label"
              item-value="key"
              hide-details
              multiple
              dense
              solo
              flat
              background-color="white"
              placeholder="Select"
            >
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0" class="mr-2">{{item.label}} <span class="grey--text caption" v-show="sectionCount(section) > 1">+{{ sectionCount(section) - 1 }} more</span></span>
              </template>

            </v-autocomplete>

          </v-col>
        </v-row>
      </v-container>

    </v-card>

    <v-toolbar flat class="panel-footer">
      <hb-link @click="resetColumns">Reset</hb-link>
      <v-spacer></v-spacer>
      <hb-link @click="$emit('close')" class="mr-3">Close</hb-link>

      <hb-btn color="primary" :disabled="set_columns.length === 0"  @click="setColumns">Set Columns</hb-btn>
    </v-toolbar>

  </div>

</template>

<script type="text/babel">
    import Status from '../Messages.vue';

    import moment from 'moment';

    export default {
        name: "Columns",
        data() {
            return {
                // pivot_mode: {
                //    enabled: false,
                //     column: {},
                //     metric: {
                //       field: {},
                //       method: ''
                //     },
                //     row: {}
                // },
                newRow:{},
                newColumn:{},
                metric:{
                    field: '',
                    method: ''
                },
                date_formats: [
                    'MM/DD/YYYY',
                    'MM/YYYY',
                    'YYYY',
                ],
                section_searcher: '',
                set_groups:[],
                selectedColumnSelectorGroup: '',
                form :{},
                form_loaded: false,
                source_data:{},
                slider_configs:{},
                rentslider:{
                    width: '100%',
                    min: 0,
                    max: 0,
                    show: true,
                    tooltip: false
                },
                set_columns: [],
                timeframe_options: ['Today', 'Yesterday', 'Last 7 Days', 'Last 30 Days', 'This Month', 'Year To Date', 'All Time', 'Custom Range']
            }
        },
        components:{

            Status
        },
        computed: {
            // metric_options(){
            //
            //    switch(this.pivot_mode.metric.field.column_type){
            //        case 'number':
            //        case 'money':
            //            return ['Sum', 'Avg', 'Lowest', 'Highest', 'Count'];
            //        case 'date':
            //             return ['Count', 'Earliest', "Latest"];
            //        case 'string':
            //        default:
            //            return ['Count'];
            //    }
            //
            // },
            selectedColumns(){
                return this.structure.filter(c => c.group === this.selectedColumnSelectorGroup)
            },
            listGroups(){
                return this.structure.filter(s => this.set_groups.indexOf(s.key) >= 0);
            },
            unique_structure(){
                return this.structure.filter(s => this.set_columns.find(c => c === s.key));
            },
            sections(){
                return [...new Set(this.structure.filter(({ group }) => group).map(({ group }) => group))]
            }
        },
        mounted(){
        },

        created(){

            // this.pivot_mode = JSON.parse(JSON.stringify(this.pivot));
            this.set_columns = this.columns.map(c => c.key);
            this.set_groups = this.groups;
            this.$nextTick(() => {
                this.form_loaded = true;
            })
        },
        destroyed(){
        },
        filters:{
          removeUnderscore(name){
            return name.replace('_', ' ');
          }
        },
        props: {
          structure: {},
          columns: {},
          pivot: {},
          shouldRestrictColumns: {
            default: true,
            type: Boolean
          }
        },
        methods:{
            resetColumns(){
                this.set_columns = this.columns.map(c => c.key);
            },
            sectionCount(section){
              return this.structure.filter(s => s.group === section && this.set_columns.find(c => c === s.key) ).length;
            },
            toggleColumn(column){

                if(this.set_columns.find( sc => sc === column.key)){
                  this.set_columns.splice(this.set_columns.indexOf(column.key), 1);
                } else {
                  this.set_columns.push(column.key);
                }
                this.setColumns();

            },
            getSectionTypes(type){
              let filteredSections = this.structure
                  .filter((s) => s.group === type)
                  .filter((f) => !this.section_searcher || f?.label.toLowerCase().indexOf(this.section_searcher.toLowerCase()) >= 0);
              return filteredSections.sort((a, b) => {
                  return a?.label.toLowerCase() < b?.label.toLowerCase() ? -1 : a?.label.toLowerCase() > b?.label.toLowerCase() ? 1 : 0;
              });
            },
            setColumns(){
                // let data = {
                //     pivot_mode: this.pivot_mode,
                //     columns: this.set_columns.map(c => this.structure.find(s => s.key === c))
                // };
                //
                // if(this.pivot_mode.enabled){
                //     if(!this.pivot_mode.column.key ||
                //         !this.pivot_mode.row.key ||
                //         !this.pivot_mode.metric.field.key ||
                //         !this.pivot_mode.metric.method
                //     ){
                //         this.errorSet('pivot', "Please fill out all fields when pivoting is enabled");
                //         return;
                //     }
                // }
                //
                // this.$emit('setColumns',  data);
                this.$emit('setColumns', this.set_columns);
            }
        },
        watch:{
            "filters.columns": {
                handler: function (columns) {
                    this.set_columns = columns;

                },
                deep: true
            },

            set_columns(){
                if(this.shouldRestrictColumns && this.set_columns.length > 20) {
                    this.set_columns = this.set_columns.slice(0, 20);
                    this.errorSet(this.$options.name, "You cannot show more than 20 columns at one time.")
                }
            },
            filter_data: {
                handler: function () {
                    this.source_data = this.filter_data;
                },
                deep: true
            }
        }
    }

</script>

<style scoped>

  .w-checkbox-input{
    display: block;
  }
  .form-column-selector-section-container{
    display: flex;
    margin: 20px 0;
    width: 600px;

  }
  .column-selector{
    border: 1px solid #e2e2e2;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 200px;
    flex: 0 1 200px;
    background: #F9FAFB;
  }

  .column-selector > div{
    border-bottom: 1px solid #e2e2e2;
    padding: 5px 10px;
    background-color: white;
  }
  .column-selector > div.last-child{
    border-bottom: none;
  }
  .form-column-selector-section{
    padding: 10px;
    border-right: 1px solid #e2e2e2;
    flex: 1 0 200px;
  }

  .form-column-selector-section > div:last-child{
    border: none;
  }
  .main-section-heading label{
    margin-bottom: 3px;
  }


  .lined-padded{
    width: 300px;
    padding: 12px 8px;
    border: 1px solid #e2e2e2;
    box-shadow: 1px 1px 3px 0px #dee5e7;
    margin: 5px 0;
  }

  .column-display-holder{
    border: 1px solid #e2e2e2;
    flex: 1 0 300px;
  }
  .column-display {
    max-height: 300px;
    overflow-y: scroll;
    position: relative;
    box-sizing: border-box;
  }
  .column-display-holder input.column-searchbar{
    border: none;
    border-bottom: 1px solid #e2e2e2;

  }
  .column-display .v-list-item {
    border-bottom: 1px solid #e2e2e2;
  }

  .column-display .v-input.v-input--checkbox {
      margin-top: 0;
  }
  .column-display .v-list-item__content {
    padding: 0;
  }

  .form-section{
    padding: 10px;
  }
  .badge{
    float: right;
    background: #00a1c8;
    color: white;
    border-radius: 30px;
    width: 22px;
    text-align: center;
    font-size: 10px;
    line-height: 15px;
    margin-top: 2px;

  }
</style>
