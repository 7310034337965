<template>
    <view-rent-notes ref="viewRentNotes" @closeNotes="notesClosed($event)" :show-resolve="showResolve" :add-note="addNote" is-bulk />
</template>
<script>
import ViewRentNotes from "../../revenue_management/rent_management/ViewRentNotes.vue";

export default {
  name: "BulkApproveRentChange",
  data() {
    return {
      items: [],
      showResolve: true,
      addNote: true
    };
  },
  mixins: [],
  components: {ViewRentNotes},
  computed: {},
  methods: {
    async show(items) {
      this.showResolve = true
      await this.$refs.viewRentNotes.show({
        rowData: items,
      });
      this.items = items;
    },

    notesClosed(event) {
      this.addNote = false
      this.showResolve = false
    }
  },
};
</script>