<template>
  <div class="editor-container body-2">
    <v-card flat style="height: 100%; overflow: scroll;">
      <div>
        <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
        <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)" :message="successGet($options.name)" status="success"></status>
        <p class="hb-default-font-size mt-2 px-5">Group your data.</p>
      </div>
      <v-radio-group hide-detail class="ma-0 pa-0" dense v-model="pivot_mode.type" >
      <v-container class="hb-table">

          <v-row class="hb-table-row">
            <v-col class="hb-table-label pl-6 pt-5" cols="4">
              Row Grouping
            </v-col>
            <v-col class="hb-table-value">

              <v-radio class="hb-table-checkbox body-2" label="Group with Row Grouping" value="group"></v-radio>
              <p color="gray"  class="hb-table-checkbox body-2">Row Grouping allows you to group by the values in one column, and perform an aggregation on the other columns.</p>

              <div v-show="pivot_mode.type === 'group'">
                <v-autocomplete
                  class="hb-table-lined-input"
                  :items="unique_structure"
                  v-model="pivot_mode.row"
                  item-text="label"
                  return-object
                  hide-details
                  clearable
                  label="Row Group"
                  background-color="white"
                ></v-autocomplete>

                <v-select
                  class="hb-table-lined-input"
                  :items="agg_options"
                  v-model="pivot_mode.agg_method"
                  return-object
                  hide-details
                  clearable
                  label="Aggregate Method"
                  background-color="white"
                ></v-select>

              </div>
            </v-col>
          </v-row>

          <v-row class="hb-table-row">
            <v-col class="hb-table-label pl-6 pt-5" cols="4">
              Pivot Mode
            </v-col>
            <v-col class="hb-table-value">

              <v-radio class="hb-table-checkbox body-2" hide-details dense label="Group with Pivot Mode" value="pivot"></v-radio>

              <p color="gray" class="hb-table-checkbox body-2">Pivoting allows you to take a column's values and turn them into columns. If you turn a column into a pivot column, you must have at least one aggregation (value) active for the configuration to make sense.</p>
              <div v-show="pivot_mode.type === 'pivot'">

                <v-autocomplete
                  class="hb-table-lined-input"
                  :items="unique_structure"
                  v-model="pivot_mode.row"
                  item-text="label"
                  return-object
                  hide-details

                  clearable
                  label="Row Group"
                  background-color="white"
                ></v-autocomplete>


                <v-autocomplete
                  class="hb-table-lined-input"
                  :items="unique_structure"
                  v-model="pivot_mode.column"
                  item-text="label"
                  return-object
                  hide-details

                  clearable
                  label="Column Group"
                  background-color="white"
                ></v-autocomplete>


                <v-autocomplete
                  class="hb-table-lined-input"
                  :items="unique_structure"
                  v-model="pivot_mode.pivot_field"
                  item-text="label"
                  return-object
                  hide-details

                  label="Aggregate Field"
                  background-color="white"
                ></v-autocomplete>


                <v-select
                  class="hb-table-lined-input"
                  :items="agg_options"
                  v-model="pivot_mode.agg_method"
                  return-object
                  hide-details
                  label="Aggregate Method"
                  background-color="white"
                ></v-select>

              </div>

            </v-col>
          </v-row>

      </v-container>
      </v-radio-group>

    </v-card>

    <v-toolbar flat class="panel-footer hb-default-font-size">
      <hb-link @click="clearGroup">
        Clear Group
      </hb-link>

      <v-spacer></v-spacer>
      <hb-link class="mr-3" @click="$emit('close')">
        Cancel
      </hb-link>

      <hb-btn color="primary" @click="setColumns">
        Next
      </hb-btn>
    </v-toolbar>

  </div>
</template>

<script type="text/babel">
    import Status from '../Messages.vue';

    import moment from 'moment';
    import { notificationMixin } from  '../../../mixins/notificationMixin.js';

    export default {
        name: "AdvancedGrouping",
        mixins: [notificationMixin],
        data() {
            return {
                pivot_mode: {
                    type: '',
                    column: {},
                    row: {},
                    pivot_field: {},
                    agg_method: '',
                },

                form_loaded: false

            }
        },
        components:{
            Status
        },
        computed: {
            agg_options(){
                return ['Sum', 'Avg', 'Min', 'Max', 'Count'];
            },
            selectedColumns(){
                return this.structure.filter(c => c.group === this.selectedColumnSelectorGroup)
            },
            unique_structure(){
                console.log(this.structure);
                return this.structure.filter(s => this.set_columns.find(c => c === s.key));
            },
            sections(){
                return [...new Set(this.structure.map(({ group }) => console.log(group) || group))]
            }
        },
        mounted(){
        },

        created(){
           this.pivot_mode = JSON.parse(JSON.stringify(this.pivot));
            this.set_columns = this.columns.map(c => c.key);
            this.$nextTick(() => {
                this.form_loaded = true;
            });
        },
        destroyed(){
        },
        filters:{
        },
        props: ['structure', 'columns', 'pivot'],
        methods:{
            clearGroup(){
              this.pivot_mode = {
                    type: '',
                    column: {},
                    row: {},
                    pivot_field: {},
                    agg_method: '',
                };
              let data = {
                  pivot_mode: this.pivot_mode
              }

                this.$emit('setColumns',  data);
            },
            sectionCount(section){
                return this.structure.filter(s => s.group === section && this.set_columns.find(c => c === s.key) ).length;
            },
            toggleColumn(column){

                if(this.set_columns.find( sc => sc === column.key)){
                    this.set_columns.splice(this.set_columns.indexOf(column.key), 1);
                } else {
                    this.set_columns.push(column.key);
                }
                this.setColumns();

            },
            addGroup(){
                this.pivot_mode.rows.push(this.newRow.key);
                this.newRow = {};
            },
            removeGroup(index){
                this.pivot_mode.rows.splice(index, 1);
            },
            getSectionTypes(type){
                return this.structure.filter(s => s.group === type).filter(f => !this.section_searcher || f.label.toLowerCase().indexOf(this.section_searcher.toLowerCase()) >= 0);
            },
            setColumns(){

                let data = {
                    pivot_mode: this.pivot_mode,
                };

                if(this.pivot_mode.type === 'group'){
                    if(!this.pivot_mode.row.key || !this.pivot_mode.agg_method){
                      this.showMessageNotification({ description: 'Please fill out all fields when grouping is enabled' });
                      return;
                    }
                }

                if(this.pivot_mode.type === 'pivot'){
                    if(!this.pivot_mode.row.key || !this.pivot_mode.column.key || !this.pivot_mode.pivot_field.key ||!this.pivot_mode.agg_method ){
                      this.showMessageNotification({ description: 'Please fill out all fields when pivoting is enabled' });
                      return;
                    }
                }


                console.log(data);

                this.$emit('setPivot',  JSON.parse(JSON.stringify(data)));
            }
        },
        watch:{
            // "filters.columns": {
            //     handler: function (columns) {
            //         this.set_columns = columns;
            //     },
            //     deep: true
            // },
            "pivot_mode.pivot_field"(){
                if(!this.form_loaded) return;
                if(!this.pivot_mode.pivot_field.key) this.pivot_mode.agg_method = '';

                if(this.agg_options.indexOf(this.pivot_mode.agg_method) < 0 ){
                    this.pivot_mode.agg_method = '';
                }
            },
            "pivot_mode.type"(){
                if(!this.form_loaded) return;
                this.pivot_mode.pivot_field = {};
                this.pivot_mode.agg_method = '';
                this.pivot_mode.column = {};
                this.pivot_mode.row = {};
            },
            set_columns(){

                if(this.pivot_mode.column.key){
                    if(!this.set_columns.find(c => c === this.pivot_mode.column.key)){
                        this.pivot_mode.column = {};
                    }
                }
                if(this.pivot_mode.row.key){
                    if(!this.set_columns.find(c => c === this.pivot_mode.row.key)){
                        this.pivot_mode.row = {};
                    }
                }
                if(this.pivot_mode.pivot_field.key){
                    if(!this.set_columns.find(c => c === this.pivot_mode.pivot_field.key)){
                        this.pivot_mode.pivot_field = {};
                        this.pivot_mode.agg_method = '';
                    }
                }
            },
            filter_data: {
                handler: function () {
                    this.source_data = this.filter_data;
                },
                deep: true
            }
        }
    }

</script>

<style scoped>
  .hb-table{
    border: 1px solid #DFE3E8;
  }
  .hb-table-row{
    border-bottom: 1px solid #DFE3E8;
  }
  .hb-table-row:last-child{
    border-bottom: none;
  }
  .hb-table-label pl-6 pt-5{
    background: #F4F6F8;
  }
  .editor-container{
    position: absolute;
    top: 55px;
    bottom: 0;
    /* overflow: scroll; */
    padding-bottom: 64px;
    width: 100%;
  }
  .panel-footer{
    border-top: 1px solid #DFE3E8;
    z-index: 100;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  .v-expansion-panels .v-expansion-panel.v-expansion-panel--active > .v-expansion-panel-header {
    min-height: auto;
  }
</style>
