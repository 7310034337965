<template>
  <div style="position: absolute; top: 0; bottom: 64px; width: 100%">
    <v-card flat class="edit-panel" style="height: 100%; overflow-y: auto">
      <div>
        <div class="border-bottom hb-text-night-light py-2 px-2 pl-4">
          Tag Rent for the Selected Tenants.
        </div>
        <hb-form
          label="Reason for Tagging Tenants"
          description="Create a quick description then connect with the copy writer to finalize copy."
        >
          <hb-text-field
            class="mt-8"
            v-model="reason"
            label="Notes:"
            placeholder="Enter Reason"
            v-validate
            hide-details
          />
        </hb-form>
      </div>
    </v-card>

    <ConfirmationPopup v-once ref="confirmationPopup" />
    <hb-bottom-action-bar @close="$emit('cancel')" class="bottom-bar-height">
      <template v-slot:right-actions>
        <hb-btn
          color="primary"
          @click="showTagChangeConfirmationPopup"
          :loading="isLoading"
        >
          Save
        </hb-btn>
      </template>
    </hb-bottom-action-bar>
  </div>
</template>

<script>
import ConfirmationPopup from "@/components/revenue_management/utils/ConfirmationPopup.vue";

import api from "../../../assets/api";

import { EventBus } from "../../../EventBus.js";
import { mapGetters } from "vuex";
import { notificationMixin } from "@/mixins/notificationMixin.js";

export default {
  name: "BulkTagRentChange",
  components: {
    ConfirmationPopup,
  },
  data() {
    return {
      isLoading: false,
      reason: "",
    };
  },
  props: ["items", "selected"],
  mixins: [notificationMixin],
  computed: {
    ...mapGetters({
      properties: "propertiesStore/filtered",
      bulkEditProperty: "propertiesStore/bulkEditProperty",
    }),
    getRentChangeIds() {
      return this.items.map((item) => item.rentchange_id);
    },
  },
  methods: {
    /**
     * Manual rent change confirmation pop up
     */
    async showTagChangeConfirmationPopup(type = "active") {
      this.confirmationType = type;
      this.$validator.validateAll().then(async (success) => {
        if (success) {
          let confirmed = await this.$refs.confirmationPopup
            .show({
              title: `Tag Rent Changes `,
              message: `You are about to Tag Rent Change to the Selected Tenants.
              <br><br>Are you sure you want to continue?`,
              buttonType: type == "delete" ? "destructive" : "primary",
              resolver: `Continue`,
            })
            .catch(() => false);
          if (confirmed) this.applyBulkTagChange();
        }
      });
    },

    async applyBulkTagChange() {
      this.isLoading = true;

      let payload = {
        rent_change_ids: this.getRentChangeIds,
        tag: true,
        note: this.reason,
      };
      try {
        await api
          .put(
            this,
            api.getPropertyRentManagementUrl(this.bulkEditProperty) +
              "rent-changes/tag",
            payload
          )
          .then(() => {
            this.showMessageNotification({
              type: "success",
              description: "Rent Changes tagged successfully.",
            });
            EventBus.$emit("reviewRentChangeEvents", {
              event: "refreshTable",
              data: "",
            });
            this.$emit("cancel");
          })
          .catch((error) => {
            console.log(error);
            this.showMessageNotification({
              type: "error",
              description:
                "Unable to Tag Rent Change for the Selected Tenants.",
            });
          });
      } catch (error) {
        console.log(error);
      }
      this.reason = "";
      this.isLoading = false;
    },
  },
  watch: {
    items: {
      deep: true,
      immediate: true,
      handler(value) {
        if (!value.length) this.$emit("cancel");
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.bottom-bar-height {
  ::v-deep .hb-bab-wrapper {
    height: 63px !important;
  }
}
</style>
