export default [
    /* Tenant Statuses */
    { color: '#02AD0F', name: 'Current', description: 'Tenant in good standing'}, // also used in space statuses
    { color: '#FFD600', name: 'Pending', description: 'Active Lead has paid but not signed the Agreement'}, // also used in space statuses
    { color: '#FFD600', name: 'Delinquent', description: 'Tenant that is 1+ Days delinquent'},
    { color: '#FFD600', name: 'Suspended', description: 'Rent, Fee or Auction/Lien Process Suspension, Pending Verified Move-Out'},
    { color: '#FB4C4C', name: 'Active Lien', description: 'Tenant has been sent an Pre-Lien or Lien Letter'},
    { color: '#FB4C4C', name: 'Bankruptcy', description: 'Tenant has filed for Bankruptcy (not a status used currently on HB)'},
    { color: '#FB4C4C', name: 'Auction', description: 'Tenant Scheduled in Auction provides an Auction date'},
    { color: '#919EAB', name: 'Lease Closed', description: 'Tenant whose space has been moved out would display a Lease Closed status'},

    /*Space Statuses */
    { color: '#02AD0F', name: 'Available', description: 'In Inventory, ready to lease'},
    { color: '#FFD600', name: 'On-Hold', description: 'When the space is rented from Mariposa, the space goes into an On-Hold status for 15 mins'},
    { color: '#FFD600', name: 'Reserved', description: 'The space is tied to an active lead and is removed from available inventory'},
    { color: '#FFD600', name: 'Future Leased', description: 'When a space is rented for a future move-in date, it would display a status Future Leased'},
    { color: '#919EAB', name: 'Offline', description: 'Space is scheduled for maintenance'},
    { color: '#919EAB', name: 'Deactivated', description: 'When a space is deactivated through Space Management'},
    { color: '#919EAB', name: 'Deleted', description: 'When a space is deleted through Space Management'},
    { color: '#FB4C4C', name: 'Remove Overlock', description: 'Previously delinquent Tenant is now Current requiring either a manual or electronic removal of restrictions'},
    { color: '#FB4C4C', name: 'To Overlock', description: 'There is an active task for the manager to place an overlock on a space'},
    { color: '#FB4C4C', name: 'Overlocked', description: 'Space has manually or electronically restricted tenant access'},
    { color: '#FB4C4C', name: 'Schedule for Auction', description: 'Space is ready to be scheduled for auction'},
    { color: '#FB4C4C', name: 'Scheduled for Auction', description: 'Delinquent Tenant in the final stages of the lien process. Can be On-site Auction or Online Auction (Time span)'},
    { color: '#FFD600', name: 'To Approve', description: 'Approval status of Rent Changes when it needs approval' },
    { color: '#02AD0F', name: 'Approved', description: 'Approval status of Rent Change when rent change has been approved'},
    { color: '#3D8FFF', name: 'Charity', description: 'Space donated for charitable purpose'},
    { color: '#3D8FFF', name: 'Company', description: 'Space occupied by the company for business purposes'},
    { color: '#3D8FFF', name: 'Leased', description: 'Space is occupied by a tenant'},
    { color: '#3D8FFF', name: 'Tenant Using Lock', description: 'The lock has been given to a tenant and they are using it as their lock'},
    { color: '#FB4C4C', name: 'Assigned Overlock', description: 'The lock is securing to a delinquent space'},


    /* Lead Statuses */
    { color: '#02AD0F', name: 'Converted Lead', description: 'When a lead is converted to a tenant'},
    { color: '#02AD0F', name: 'Converted', description: 'When a lead is converted to a tenant'},
    { color: '#FFD600', name: 'Active Lead', description: 'Tenant that is digitally or manually nurtured'},
    { color: '#FFD600', name: 'Active', description: 'Tenant that is digitally or manually nurtured'},
    { color: '#919EAB', name: 'Retired Lead', description: 'Digitally or manually exhausted'},
    { color: '#919EAB', name: 'Retired', description: 'Digitally or manually exhausted'},
    
    /* Invoice Statuses */
    { color: '#02AD0F', name: 'Paid', description: 'The state that displays an invoice has been fully paid'},
    { color: '#FFD600', name: 'Open', description: 'When an invoice that is not due and has some pending charges'},
    { color: '#919EAB', name: 'Void', description: 'When an invoice is voided'},
    { color: '#919EAB', name: 'Write Off', description: 'When an invoice is written off as a loss'},
    { color: '#FB4C4C', name: 'Past Due', description: 'When an invoice is due or past due and has incomplete or no payments associated with it'},
    
    /* Document Statuses */
    { color: '#02AD0F', name: 'Signed', description: 'When a document is completely executed'},
    { color: '#FFD600', name: 'Signing in Progress', description: 'When the document signing is in progress'},
    { color: '#FFD600', name: 'Ready to Sign', description: 'When a document is generated and ready to be signed'},
    { color: '#3D8FFF', name: 'Generated', description: 'When a document that has no signature is generated'},
    { color: '#3D8FFF', name: 'Uploaded', description: 'When a document is uploaded'},


    /* to be deprectated? still keep just in case*/
    { color: '#FFD600', name: 'Scheduled Move-Out', description: 'Intent to Move-Out'},
    { color: '#FFD600', name: 'Gate Lockout', description: 'Delinquent or problem tenant that has restricted access - Revoke Gate Access, Pending Lease Signature'},
    { color: '#FFD600', name: 'Balance Due', description: 'Lease is closed but there is still a balance due'},

    { color: '#02AD0F', name: 'Complete', description: 'Auction Completed'},
    { color: '#FFD600', name: 'Auction Payment', description: 'Auction Payment Required'},
    { color: '#FB4C4C', name: 'Auction Day', description: 'Auction Day'},
    { color: '#FFD600', name: 'To Move Out', description: 'Space To Move Out after Auction'},
    { color: '#FB4C4C', name: 'Scheduled', description: 'Space scheduled for auction'},
    { color: '#FB4C4C', name: 'To Schedule', description: 'Space needs to be scheduled for auction'},
  ]