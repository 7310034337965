<!-- New file created by BCT for space-management -->
<template>
  <hb-modal
    v-model="dialog"
    size="medium"
    :title=" `Unable to ${bulkEdit ? 'Bulk Edit' : 'Add'} Spaces`"
    confirmation
    @close="onCloseConfirmationDialog"
    :footer-off="true"
    show-help-link
  >
    <template v-slot:content>
      <div class="pa-4">
        There are multiple properties selected.<br />
        {{`In order to ${bulkEdit ? 'bulk edit' : 'add'} spaces, only one property can be selected.`}}
      </div>
    </template>
  </hb-modal>
</template>
<script>
export default {
  name: "MultiplePropertySelected",
  props: {
    value: {
      type: Boolean
    },
    bulkEdit: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set() {}
    }
  },
  methods: {
    onCloseConfirmationDialog() {
      this.$emit("close");
    }
  }
};
</script>
