<template>
  <div>
    <v-checkbox class="ma-0 pa-0" v-model="selected" @change="updateSelected"></v-checkbox>
  </div>
</template>

<script>
    import Vue from 'vue';


    export default Vue.extend({
        name: "CheckboxRenderer",
        data() {
            return {
                selected: false
            };
        },
        components:{

        },
        computed:{

        },
        methods: {
            updateSelected(){
              this.params.handleSelect(this.params.node, this.selected);
            }
        },
        watch: {
            params: {
                deep: true,
                //immediate: true,
                handler: (params) => {

                }
            },
        },
        created() {
            this.selected = this.params.data.select;
        },
    });
</script>
