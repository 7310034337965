<script type="text/babel">
export default {
    computed: {
        statusText() {
            return this.params.value ? 'Yes' : 'No'
        },
        icon() {
            return {
                true: this.params.true_icon || 'mdi-table-actions-enable',
                false: this.params.false_icon || 'mdi-table-actions-disable'
            }[!!this.params.value]
        }
    }
}
</script>

<template>
    <span>
        <hb-icon v-if="this.params.icon">{{ icon }}</hb-icon>
        <template v-else>{{ statusText }}</template>
    </span>
</template>