<template>
  <div>
    <assign-rent-plan
      ref="changeRentPlanModal"
      :items="items"
      :is-value-tiered="false"
      @selected="updateChangedPlan($event)"
      @close="$emit('input', false)"
    />
  </div>
</template>

<script>
import api from "../../../assets/api.js";

import { mapState, mapGetters } from "vuex";
import { notificationMixin } from "@/mixins/notificationMixin.js";
import AssignRentPlan from '../../revenue_management/rent_management/AssignRentPlan.vue';
import { EventBus } from '../../../EventBus.js';

export default {
  name: "BulkAssignRentPlan",
  props: ["items", "value"],
  data() {
    return {
      showConfirmation: false,
    };
  },
  mixins: [notificationMixin],
  components: {AssignRentPlan},
  watch: {
    value(val) {
      if (val) 
        this.$nextTick(() => {
          this.changeRentPlan()
        })
    }
  },
  computed: {
    ...mapState({
      rentPlans: (state) => state.revManStore?.rent?.plans ?? [],
    }),
    ...mapGetters({
      properties: "propertiesStore/filtered",
      bulkEditProperty: "propertiesStore/bulkEditProperty",
    }),
  },
  beforeDestroy(){
      this.$refs?.changeRentPlanModal
        ._cancel()
  },
  methods: {
    /**
     * Function to open change rent plans modal
     */

    async changeRentPlan(data) {
      await this.$refs?.changeRentPlanModal
        .show({
          rentPlans: this.rentPlans.length ? this.rentPlans : [],
          currentPlanId: data?.tenant_rent_plan_id ?? "",
          rowData: { ...data },
        })
        .catch(() => ({ error: true }));
    },
    /**
     * Function for changing selected rent plan (API call)
     */

    async updateChangedPlan(data) {
      let body = this.items.map(item => ({
        rent_plan_id: data.selectedPlanId,
        lease_id: item.lease_id
      }));
      await api
        .put(
          this,
          api.getPropertyRentManagementUrl(this.bulkEditProperty) +
            `leases/plan`,
          body
        )
        .then(res => {
          console.log('res', res)
          this.showMessageNotification({
            type: "success",
            description: "The Rent Management plan for the selected leases has been updated successfully.",
          });
          EventBus.$emit("tenantRentManagementEvent", { event: 'refreshTable' })
          this.$emit('cancel')
        })
        .catch((error) => {
          console.log(error);
          this.showMessageNotification({
            type: "error",
            description: "Rent Plan update failed.",
          });
        });
    },
    close() {
      this.showConfirmation = false;
      this.$emit("close");
    },
  },
};
</script>

<style></style>
