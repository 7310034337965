<template>

  <div class="section-content accounting-export-history-wrapper">
    <div style="flex: 1">
      <hb-report
        :key="report_key"
        v-if="view || report_id"
        :report_id="report_id"
        :report_type="view"
        :actions_panel="['advanced', 'filters', 'export', 'bulk_edit', 'columns', 'save']"
        right_click
        row_click
        show_search
        class="mr-4"
      ></hb-report>
    </div>
  </div>
</template>

<script type="text/babel">
import HbReport from '../assets/HummingbirdReportViewer.vue';
export default {
    name: "History",
    data() {
            return {
                report_key: 0,
                view: '',
                report_id: '',
                report_accounting_export:
                    {
                        name: "Accounting Export",  key: 'accounting_exports', type: "accounting_exports", description: "Accounting Export history" 
                    },
                
            }
        },
        components:{
            HbReport
        },
        async created(){
              this.view = this.report_accounting_export.key;

        },
        destroyed(){
        },
        filters:{

        },
};
</script>

<style scoped>
  .section-content {
    display: flex;
    flex-direction: column;
    outline: 0;
    width: 100%;
    height: 100%;
    padding: 2px 20px 10px 0 !important;
  }
</style>

<style>
  .accounting-export-history-wrapper .v-divider.space-tabs-bottom-divider {
    border: none;
  }
</style>
