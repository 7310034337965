<template>
  <hb-modal
    v-model="notesModal"
    :title="title"
    :close-instead-of-cancel="notesModalView !== 'add'"
    @close="closeNotesModal()"
    show-help-link
  >
    <template v-slot:subheader>
      {{ description }}
    </template>
    <template v-slot:content>
      <div class="pa-0">
        <div class="mt-2" :class="!addNote && notes.length ? 'mb-n2' : 'mb-2'">
          <HbTextarea
            v-if="notesModalView == 'add'"
            v-model="notesModalTextarea"
            v-validate.disable="'required|max:1000'"
            placeholder="Enter Note"
            name="note"
            data-vv-as="Note"
            :error="errors.collect('note').length > 0"
            :rows="notes.length == 0 ? 16 : 13"
            class="pt-1 px-4"
            autofocus
            @click="activeIndex = null"
          />
          <v-divider
            v-if="notesModalView == 'add' && notes.length"
            class="mt-4"
          />
        </div>
        <div
          class="notes-modal-content notes-modal-notes"
          v-if="!addNote && (notes.length || loading)"
        >
          <span v-if="loading">
            <v-skeleton-loader
              v-for="n in 4"
              :key="n"
              type="list-item-two-line"
              class="profile-loader px-3 pt-3"
            />
            <v-skeleton-loader
              type="list-item"
              class="profile-loader px-3 pt-3"
            />
          </span>
          <span v-else-if="notes.length">
            <div
              v-for="(note, i) in notes"
              :key="'note_' + note.id"
              class="px-3 pt-3"
              :class="{ 'pb-3': i == notes.length - 1 }"
            >
              <hb-communication
                type="note"
                :system-generated="note.created_by.first + ' ' + note.created_by.last === 'System Generated'"
                :admin-name="note.created_by.first + ' ' + note.created_by.last"
                :time="note.created_at | momentNotesTime"
                :active="i == activeIndex"
                :expanded.sync="note.expanded"
                @click="activeIndex = i"
                disable-pinning
              >
                {{ note.content }}
              </hb-communication>
            </div>
          </span>
          <div v-else class="my-1">&nbsp;</div>
        </div>
      </div>
    </template>
    <template v-slot:secondary v-if="showResolve">
      <v-divider></v-divider>
      <div class="py-2 px-3">
        <hb-checkbox
          v-model="notesModalResolvedCheckbox"
          label="Mark document as Resolved."
        ></hb-checkbox>
      </div>
    </template>
    <template v-slot:right-actions>
      <hb-btn
        v-if="notesModalView !== 'add'"
        color="secondary"
        @click="notesModalAdd()"
        >+ Add Note</hb-btn
      >
      <hb-btn
        v-if="notesModalView === 'add'"
        @click="notesModalSave()"
        :loading="notesModalAddingNoteInProgress"
        :disabled="!isDirty"
        >Save</hb-btn
      >
    </template>
  </hb-modal>
</template>

<script>
import { EventBus } from "../../../EventBus";
import { mapGetters } from "vuex";

import { cloneDeep } from "lodash";
import moment from "moment";

import api from "../../../assets/api.js";
import { notificationMixin } from "@/mixins/notificationMixin.js";

export default {
  name: "ViewRentNotes",
  components: {},
  mixins: [notificationMixin],
  props: {
    showResolve: {
      type: Boolean,
      default: false,
    },
    addNote: {
      type: Boolean,
      default: false,
    },
    isBulk: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      notesModal: false,
      hasNotes: true,
      notes: [],
      rowData: {},
      loading: false,
      notesModalView: "",
      notesModalTextarea: "",
      lastAddedNote: "",
      notesModalAllowAdd: false,
      notesModalAddingNoteInProgress: false,
      notesModalResolvedCheckbox: false,
      activeIndex: "",
    };
  },
  computed: {
    ...mapGetters({
      selectedProperty: "revManStore/getSelectedProperty",
    }),
    title() {
      return this.showResolve
      ? 'Resolve Notification Status'
      : !this.rowData.rentchange_recent_note || this.notesModalView == 'add' ? 'Add Notes' : 'View Notes'
    },
    description() {
      return this.showResolve ? 'Resolve and Add notes relating to Rent changes.' : 'View and Add notes relating to Rent changes.'
    },
    checkboxChanged() {
      let initialValue = this.rowData.rentchange_notification_status == 'resolved'
      return  initialValue != !!this.notesModalResolvedCheckbox
    },
    /**
     * returns false on following conditions:
     * - there should be content in notes textarea
     * - if resolve checkbox is displayed, it should be changed
     */
    isDirty() {
      if(this.notesModalTextarea.length) {
        if(this.showResolve) return this.checkboxChanged
        return true
      } 
      return false
    }
  },
  methods: {
    show(data) {
      this.notesModal = true;
      this.lastAddedNote = "";
      this.rowData = data.rowData;
      this.notesModalResolvedCheckbox = this.isBulk ? false : this.rowData.rentchange_notification_status == 'resolved'
      if (!this.rowData.rentchange_recent_note || this.addNote) this.notesModalAdd();
      else this.getNotes();
    },
    notesModalAdd() {
      this.notesModalView = "add";
      this.activeIndex = null;
    },

    async getNotes() {
      this.loading = true;
      try {
        await api
          .get(
            this,
            api.getPropertyRentManagementUrl(this.selectedProperty) +
              `rent-changes/${this.rowData.rentchange_id}/notes`
          )
          .then((response) => {
            this.$set(this, "notes", response.notes);
          })
          .catch((error) => {
            console.log("unable to fetch notes", error);
            this.showMessageNotification({
              type: "error",
              description: "Unable to Fetch Notes",
            });
          });
        if (!this.notes.length) this.notesModalAdd();
      } catch {
        console.log("getting notes API failed");
      }
      this.loading = false;
    },

    async notesModalSave() {
      this.notesModalAddingNoteInProgress = true;
      await this.$validator.validateAll().then(async (success) => {
        if (success) {
          let payload;
          if (this.showResolve) {
            payload = {
              resolve: !!this.notesModalResolvedCheckbox,
              note: this.notesModalTextarea,
            };
            payload['rent_change_ids'] = this.isBulk ? this.rowData.map(item => item.rentchange_id): [this.rowData.rentchange_id]
            try {
              await api.put(
                this,
                api.getPropertyRentManagementUrl(this.selectedProperty) +
                  `rent-changes/resolve`,
                payload
              )
              .then(async () => {
                this.showMessageNotification({
                  type: "success",
                  description: `Notification Status ${this.notesModalResolvedCheckbox ? 'resolved':'unresolved'} successfully.`,
                });
                this.notesModalTextarea = "";

                EventBus.$emit("reviewRentChangeEvents", {
                    event: "refreshTable",
                });
                this.closeNotesModal()
              })
              .catch((error) => {
                console.log(error);
                this.showMessageNotification({
                  type: "error",
                  description: `Failed to ${this.notesModalResolvedCheckbox ? 'resolve':'unresolve'} Notification Status.`,
                });
                this.closeNotesModal()
              });
            } catch (error) {
              console.log(error);
            }
          } else {
            payload = {
              note: this.notesModalTextarea,
            };
            try {
              await api
                .post(
                  this,
                  api.getPropertyRentManagementUrl(this.selectedProperty) +
                    `rent-changes/${this.rowData.rentchange_id}/notes`,
                  payload
                )
                .then(async () => {
                  this.showMessageNotification({
                    type: "success",
                    description: "Rent Note added successfully.",
                  });
                  this.notesModalView = "view";
                  await this.getNotes();
                  this.lastAddedNote = cloneDeep(this.notesModalTextarea);
                  this.notesModalTextarea = "";
                })
                .catch((error) => {
                  console.log(error);
                  this.showMessageNotification({
                    type: "error",
                    description: "Failed to add Rent Note.",
                  });
                });
            } catch (error) {
              console.log(error);
            }
          }
          this.activeIndex = null;
        }
      });
      this.notesModalAddingNoteInProgress = false;
    },
    closeNotesModal() {
      this.$emit("closeNotes", {
        rentchange_id: this.rowData.rentchange_id,
        note: this.lastAddedNote,
      });
      this.notesModal = false;
      this.activeIndex = null;
      this.notes = [];
      this.notesModalTextarea = "";
      this.notesModalView = "";
      this.rowData = {};
    },
  },
  filters: {
    momentNotesTime: function (value) {
      if (!value) return "";
      let today = moment.utc().local().startOf("day").calendar();

      if (moment.utc(value).local().startOf("day").calendar() == today) {
        return moment.utc(value).local().format("h:mma");
      } else {
        return moment.utc(value).local().format("MMM D, YYYY [ @ ] h:mma");
      }
    },
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
.notes-modal-content {
  position: relative;
  min-height: 400px;
}

.notes-modal-notes {
  background: #f4f6f8;
}
</style>
