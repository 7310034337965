<template>
  <div style="position: absolute; top: 0; bottom: 64px; width: 100%">
    <v-card flat class="edit-panel" style="height: 100%; overflow-y: auto">
      <div>
        <div class="border-bottom hb-text-night-light py-2 px-2 pl-4">
          Skip or cancel a rent change within a deployment month
        </div>
        <hb-form :full="true" label="Next Rent Change " class="px-0 py-0">
          <div class="hb-text-night-light mb-2 pb-2">
            Select whether to Skip or Cancel upcoming rent changes for this
            tenant. Then provide a reason for the change
          </div>
          <hb-radio-group class="pt-0" v-model="skipCancelRadio">
            <hb-radio value="skip" label="Skip Rent Change" class="pt-0" dense>
              <template v-slot:description>
                <span class="hb-font-caption hb-text-night-light mt-0">
                  Skipping a rent change postpones it by one month or by the next notification period. 
                </span>
              </template>
            </hb-radio>
            <hb-radio
              value="cancel"
              label="Cancel Rent Change"
              class="pt-0"
              dense
            >
              <template v-slot:description>
                <span class="hb-font-caption hb-text-night-light mt-0">
                  Canceling a rent change removes it entirely from the designated stage, preventing it from occurring again. 
                  This is a permanent removal of the rent adjustment.
                </span>
              </template>
            </hb-radio>
          </hb-radio-group>
          <div class="pt-2 mb-2">
            <div class="pb-0 mb-0 hb-font-caption hb-text-night-light">
              Notes:
            </div>
            <v-col cols="10 ma-0 pa-0">
              <HbTextarea
                v-model="rentChangeReason"
                label="Reason for rent change:"
                placeholder="Enter Reason"
                maxlength="250"
              />
            </v-col>
          </div>
        </hb-form>
      </div>
    </v-card>

    <ConfirmationPopup v-once ref="confirmationPopup" />
    <hb-bottom-action-bar @close="$emit('cancel')" class="bottom-bar-height">
      <template v-slot:right-actions>
        <hb-btn
          :loading="loading"
          :disabled="!isDirty"
          @click="showConfirmationPopup"
        >
          Save
        </hb-btn>
      </template>
    </hb-bottom-action-bar>
  </div>
</template>

<script>
import ConfirmationPopup from "@/components/revenue_management/utils/ConfirmationPopup.vue";

import api from "../../../assets/api";

import { EventBus } from "../../../EventBus.js";
import { mapGetters } from "vuex";
import { notificationMixin } from "@/mixins/notificationMixin.js";

export default {
  name: "BulkSkipCancel",
  components: {
    ConfirmationPopup,
  },
  props: ["items", "selected"],
  mixins: [notificationMixin],

  data() {
    return {
      rentChangeReason: "",
      skipCancelRadio: "",
      showSkipCancelRent: this.showSkipCancel,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      properties: "propertiesStore/filtered",
      bulkEditProperty: "propertiesStore/bulkEditProperty",
    }),
    successDescription() {
      return `You have ${
        this.isSkipped ? "skipped" : "cancelled"
      } the upcoming rent change.`;
    },
    getRentChangeIds() {
      return this.items.map((item) => item.rentchange_id);
    },

    isSkipped() {
      return this.skipCancelRadio === "skip";
    },

    validateMonth() {
      return this.isSkipped ? "required" : "";
    },
    isDirty() {
      return !!(this.skipCancelRadio && this.rentChangeReason)
    }
  },
  methods: {
    /**
     * Update Rent change
     */
    async updateTenantRentPlan() {
      this.loading = true;
      let body = {
        rent_change_ids: this.getRentChangeIds,
        note: this.rentChangeReason ?? "",
      };
      let url =
        api.getPropertyRentManagementUrl(this.bulkEditProperty) +
        `rent-changes/${this.skipCancelRadio}`;
      await api
        .put(this, url, body)
        .then(() => {
          this.showMessageNotification({
            type: "success",
            description: this.successDescription,
          });
          EventBus.$emit("reviewRentChangeEvents", {
            event: "refreshTable",
            data: "",
          });
          EventBus.$emit("rentChangeQueueEvents", "refreshTable");
          this.$emit("cancel");
        })
        .catch((error) => {
          this.showMessageNotification({
            type: "error",
            description: error,
          });
        });
      this.loading = false;
    },

    /**
     *	confirmation pop up
     */
    async showConfirmationPopup(type = "active") {
      this.confirmationType = type;
      let confirmed = await this.$refs.confirmationPopup
        .show({
          title: `Confirmation ${
            this.isSkipped ? "Skip" : "Cancel"
          } Next Rent Change `,
          message: `Are sure you want to ${
            this.isSkipped ? "Skip" : "Cancel"
          } Next Rent Change?`,
          buttonType: type == "delete" ? "destructive" : "primary",
          resolver: `Continue`,
        })
        .catch(() => false);
      if (confirmed) this.updateTenantRentPlan();
    },
  },
  watch: {
    showSkipCancel: {
      handler(value) {
        this.showSkipCancelRent = value;
      },
      immediate: true,
    },
    items: {
      deep: true,
      immediate: true,
      handler(value) {
        if(!value.length) this.$emit("cancel");
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.bottom-bar-height {
  ::v-deep .hb-bab-wrapper {
    height: 63px !important;
  }
}
</style>
