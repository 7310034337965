<template>
  <div style="position: absolute; top: 0; bottom: 64px; width: 100%">
    <v-card flat class="edit-panel" style="height: 100%; overflow-y: auto">
      <div>
        <div class="border-bottom hb-text-night-light py-2 px-2 pl-4">
          Modify the scheduled rent amount for the user. 
          Adjusting the rent amount may result in changes to the scheduled timeframe for deployment.
        </div>
        <RentChangeForm
          :currentRent="0"
          :rentData="{}"
          :ids="getRentChangeIds"
          :is-edit="true"
          :bulk="true"
          ref="rentChangeFormRef"
          @validForm="onValidForm"
          @loading="loading = $event"
          @cancel="$emit('cancel')"
        />
      </div>
    </v-card>

    <hb-bottom-action-bar @close="$emit('cancel')" class="bottom-bar-height">
      <template v-slot:right-actions>
        <hb-btn
          :loading="loading"
          color="primary"
          @click="applyEditRentChange"
          :disabled="!enableEditRentChange"
        >
          Save
        </hb-btn>
      </template>
    </hb-bottom-action-bar>
  </div>
</template>

<script>
import RentChangeForm from "../../revenue_management/rent_management/RentChangeForm.vue";
import { mapActions, mapGetters } from "vuex";
import { notificationMixin } from "@/mixins/notificationMixin.js";

export default {
  name: "BulkEditRentChange",
  components: {
    RentChangeForm,
  },
  data() {
    return {
      amenityList: {},
      updatedAmenities: [],
      enableEditRentChange: false,
      manualRentChangeData: {},
      loading: false,
    };
  },
  props: ["items", "selected"],
  mixins: [notificationMixin],
  computed: {
    ...mapGetters({
      properties: "propertiesStore/filtered",
      bulkEditProperty: "propertiesStore/bulkEditProperty",
    }),
    getRentChangeIds() {
      return this.items.map((item) => item.rentchange_id);
    },
  },

  async created() {},

  methods: {
    ...mapActions({}),

    onValidForm(value) {
      this.enableEditRentChange = value;
    },

    applyEditRentChange() {
      this.$refs.rentChangeFormRef.applyManualRentChange(true);
    },
  },
  watch: {
    items: {
      deep: true,
      immediate: true,
      handler(value) {
        if (!value.length) this.$emit("cancel");
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.bottom-bar-height {
  ::v-deep .hb-bab-wrapper {
    height: 63px !important;
  }
}
</style>
