<template>
  <hb-modal size="medium" :title="title" v-model="dialog" confirmation show-help-link>
    <template v-slot:content>
      <div class="content py-4 px-6 d-flex flex-column">
        <div v-html="message"></div>
      </div>
    </template>
    <template v-slot:right-actions>
      <hb-btn :loading="approvalLoading" @click="approveRentChanges">
        Continue
      </hb-btn>
    </template>
  </hb-modal>
</template>

<script>
import api from "../../../assets/api.js";

import { mapState, mapGetters } from "vuex";
import { notificationMixin } from "@/mixins/notificationMixin.js";
import { EventBus } from "../../../EventBus.js";

export default {
  name: "BulkApproveRentChange",
  data() {
    return {
      dialog: false,
      items: [],
      title: `Confirmation`,
      message: `Are you sure you want to <span class="font-weight-medium">Approve All Rent Changes</span> for the selected Tenants?`,
      approvalLoading: false,
    };
  },
  mixins: [notificationMixin],
  components: {},
  computed: {
    ...mapState({
      rentPlans: (state) => state.revManStore?.rent?.plans ?? [],
    }),
    ...mapGetters({
      properties: "propertiesStore/filtered",
      bulkEditProperty: "propertiesStore/bulkEditProperty",
    }),
    getRentChangeIds() {
      return this.items.map((item) => item.rentchange_id);
    },
  },
  methods: {
    /**
     * Function to open change rent plans modal
     */
    show(items) {
      this.items = items;
      this.dialog = true;
    },

    async approveRentChanges() {
      this.approvalLoading = true;
      let body = {
        rent_change_ids: this.getRentChangeIds,
      };
      try {
        await api
          .put(
            this,
            api.getPropertyRentManagementUrl(this.bulkEditProperty) +
              `rent-changes/approve`,
            body,
            "",
            false
          )
          .then((response) => {
            console.log(response);
            this.showMessageNotification({
              type: "success",
              description: `Rent Changes Approved Successfully`,
            });
            this.dialog = false;
            EventBus.$emit("reviewRentChangeEvents", {
              event: "refreshTable",
              data: "",
            });
            EventBus.$emit("rentChangeQueueEvents", "refreshTable");
          })
          .catch((error) => {
            console.log(error);
            this.showMessageNotification({
              type: "error",
              description: `Rent Changes Approval failed ${
                error?.body?.message ? `because ${error?.body?.message}` : ``
              }`,
            });
          });
      } catch (error) {
        console.log(error, " approval API failed");
      }
      this.approvalLoading = false;
    },
  },
};
</script>

<style></style>
