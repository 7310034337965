<template>
  <div class="promo-details" :class="{ 'wrap-text': wrapText }">
    <v-tooltip
      bottom
      open-delay="250"
      v-if="promotionId && promoData && Object.keys(promoData).length"
    >
      <template v-slot:activator="{ on }">
        <v-hover v-slot:default="{ hover }">
          <span v-on="on" class="border-bottom-dashed">
            {{ promoData.name }}
          </span>
        </v-hover>
      </template>
      <span>
        <div class="py-1" style="width: 201px">
          <span class="font-weight-medium">Name: </span>
          <span class="text-body-2 line-clamp">{{ promoData.name }} </span>
          <br />
          <span class="font-weight-medium" v-if="promoData.description">
            Description:
          </span>
          <span class="text-body-2 line-clamp" v-if="promoData.description">
            {{ promoData.description }}
          </span>
          <br />
          <span
            class="font-weight-medium"
            v-if="promoData.Creator && promoData.Creator.id"
          >
            Created By:
            {{ promoData.Creator.first }}
            {{ promoData.Creator.last }}
          </span>
          <br />
          <span class="text-body-2">
            {{
              promoData.created_at
                | formatDateTimeCustom("MMM DD, YYYY @ h:mm a")
            }}
          </span>
        </div>
      </span>
    </v-tooltip>
    <br />
    <span v-if="promoData && promoData.description" class="hb-text-light">
      <template>
        <span class="text-capitalize">{{ promoData.description }}</span>
      </template>
    </span>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "MultilinePromoViewer",
  props: {
    promotionId: {
      type: String,
      required: true,
      default: "",
    },
    wrapText: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      promoData: null,
    };
  },
  async created() {
    await this.getPromoDetails(this.promotionId);
  },
  watch: {
    promotionId: {
      handler(val) {
        this.$nextTick(() => {
          this.getPromoDetails(val);
        });
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      activePromotions: "revManStore/getActivePromotions",
    }),
  },
  methods: {
    /**
     * Function to get details of a promotion
     *
     * @returns {Object} promotion details
     */
    async getPromoDetails(id) {
      this.promoData = this.activePromotions.length
        ? this.activePromotions.find((promotion) => promotion.id === id)
        : {};
    },
  },
};
</script>
<style lang="scss" scoped>
.border-bottom-dashed {
  border-bottom: 1px dashed;
}
.promo-details {
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: nowrap;
}
.wrap-text {
  text-wrap: wrap !important;
}
</style>
