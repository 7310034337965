<template>
  <div>
    <v-card flat class="edit-panel">
      <div>
        <status
          @resetStatus="errorClear($options.name)"
          v-if="errorHas($options.name)"
          :message="errorGet($options.name)"
          status="error"
        ></status>
        <status
          @resetStatus="successClear($options.name)"
          v-if="successHas($options.name)"
          :message="successGet($options.name)"
          status="success"
        ></status>
        <p class="font-regular mt-2 px-5">Change Lead Status</p>
      </div>
      <v-container class="hb-table">
        <v-row class="hb-table-row">
          <v-col class="hb-table-label" cols="4">Status</v-col>
          <v-col class="hb-table-value">
            <v-select
              placeholder="Select Status"
              dense
              solo
              flat
              hide-details
              v-model="status"
              :items="statuses"
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-toolbar flat class="panel-footer">
      <v-spacer></v-spacer>
      <hb-btn color="secondary" class="mr-2" @click="$emit('cancel')">Back</hb-btn>
      <hb-btn color="primary" @click="save">Save</hb-btn>
    </v-toolbar>
  </div>
</template>
<script>
import api from "../../../assets/api.js";
import Status from "../Messages.vue";
import Loader from "../../assets/CircleSpinner.vue";
import { EventBus } from "../../../EventBus.js";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "BulkChangeLeadStatus",
  data() {
    return {
      showEditForm: false,
      show_dialog: false,
      units: [],
      key: '',
      status: 'active',
      statuses: [
        {
          text: "Active",
          value: "active"
        },
        {
          text: "Archived",
          value: "archived"
        },
        {
          text: "Converted",
          value: "converted"
        },
        {
          text: "New",
          value: "new"
        },
        {
          text: "Not Contacted",
          value: "not contacted"
        },
        {
          text: "Prospect",
          value: "prospect"
        }
      ]
    };
  },
  props: ['items'],
  created() {
  },
  components: {
    Status,
    Loader
  },
  computed: {
    ...mapGetters({
      bulk_edit_items: "bulkEditStore/items"
    })
  },
  methods: {
    ...mapActions({
      resetAction: "bulkEditStore/resetAction"
    }),
    closeWindow() {
      this.resetAction();
    },
    async save() {
      let data = {
          lead_ids: this.items.map(item => item.lead_id),
          form: {}
      };
      data.form['status'] = this.status;
      let response = await api.put(this, api.LEADS + 'bulk-edit', data );
      if(!response) return;
      this.successSet(this.$options.name, "Leads Saved!");
      EventBus.$emit('lead_saved');
    }
  }
};
</script>
