<script type="text/babel">
export default {
    data() {
        return {

        };
    },
    created() {
        console.log(this.params);
        
    },
    computed: {
    },
};
</script>

<template>
    <div>
        <template v-if="params.valueFormatted">
            <audio controls preload="metadata" class="small-audio audio-control-charm">
                <source :src="params.valueFormatted" type="audio/mpeg">
                Your browser does not support the audio element.
            </audio>
        </template>
    </div>
</template>
<style scoped>
.small-audio {
    height: 35px;
    width: 100%;
    padding-bottom: 5px;
}

.small-audio::-webkit-media-controls {
    height: 20px;
}
</style>
