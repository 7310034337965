<template>
  <div class="baseTooltip" v-show="showTooltip">
    <span v-for="v in value" v-bind:key="v" class="Rule-Selection">
      <div v-text="v"></div>
    </span>
  </div>
</template>

<script type="text/babel">
import Vue from "vue";
export default Vue.extend({
  name: "ReportTooltip",
  data() {
    return {
      showTooltip: true,
      value: null,
    };
  },
  beforeMount() {
    let columnWidth = this.params.column.actualWidth - 36;
    let textWidth = this.getTextWidth(this.params.value.toString());
    this.showTooltip = textWidth < columnWidth ? false : true;
    let customItems = String(this.params.valueFormatted || this.params.value || "").split(",");
    if (!this.showTooltip)
      this.showTooltip = customItems.length > 4 ? true : false;
    /*if(customItems.length > 1){
          this.value = this.params.value.replace(/,/g, '\r\n');
          return;
        }*/
    switch (this.params.column.colId) {
      case "accounting_exports_sent_to":
        if (this.params.value != "Local") this.showTooltip = true;
        break;
      // Added By BCT Team
      case "unit_amenities":
        if (!this.showTooltip && customItems.length > 4)
          this.showTooltip = true;
        break;
      case "tenant_last_contacted_message":
        if (this.params.value) {
          let _value = this.$options.filters.removeHtml(
            this.params.value,
            true
          );
          customItems = _value.split(",");
        }
        break;
    }
    this.value = customItems;
  },
  methods: {
    getTextWidth(inputText) {
      let font = "12px 'Graphik Web', sans-serif";
      let canvas = document.createElement("canvas");
      let context = canvas.getContext("2d");
      context.font = font;
      let width = context.measureText(inputText).width;
      return Math.ceil(width);
    },
  },
});
</script>

<style scoped>
.baseTooltip {
  position: absolute;
  width: 200px;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  padding: 8px 12px;
  border-radius: 2px;
  box-shadow: 0 1px 2px 1px rgba(11, 11, 11, 0.05),
    0 1.5px 2px -1px rgba(0, 0, 0, 0.5);
  background-color: #334450;
  transition: none !important;
}

.Rule-Selection {
  align-self: stretch;
  flex-grow: 0;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}
</style>
