<!-- BCT: This file is added by BCT -->
<!-- BCT: This file is added by BCT -->
<!-- BCT: This file is added by BCT -->
<template>
  <div>
    <!-- // confirmation popup -->
    <hb-modal
      confirmation
      v-if="showConfirmation"
      v-model="showConfirmation"
      size="medium"
      :title="'Delete Spaces'"
      :footerCancelOption="false"
      @close="close"
      show-help-link
    >
      <template v-slot:content>
        <div class="pa-6">
            You are about to delete {{items.length}} spaces.
        </div>
        <div class="pl-6 pb-6">
            Are you sure you want to delete selected spaces?
        </div>
      </template>
      <template v-slot:right-actions>
        <hb-btn @click="deleteSpaces" color="primary" :disabled="isLoading('delete-spaces')">Delete</hb-btn>
      </template>
    </hb-modal>

  </div>
</template>

<script>
import api from '../../../assets/api.js';
import { EventBus } from '../../../EventBus.js';
import { notificationMixin } from  '@/mixins/notificationMixin.js';

export default {
    name: 'DeleteSpaces',
    props: ['items'],
    data() {
        return {
            showConfirmation: false,
        }
    },
    mixins: [notificationMixin],
    created() {
      this.showConfirmation = true;
    },
    methods: {
        deleteSpaces() {
            this.startLoading('delete-spaces');
            let spacesNumbers = this.items.map(a => a.unit_number);
            let body = { units: []};

            this.items.map(a => {
              body.units.push({id : a.unit_id});
            });

            api.put(this, api.SPACE_MANAGEMENT + "delete-spaces", body).then((r) => {
              this.stopLoading('delete-spaces');
              let msg = `Spaces ${spacesNumbers.join(', ')} have been deleted.`;
              this.showMessageNotification({type: 'success', description: msg});

              EventBus.$emit('handle_select_all', false);
              EventBus.$emit('unit_edited');
            }).catch(e => {
              let errList = [];
              let errMsg = 'Something went wrong.!';
              if (e.leased && e.leased.length) {
                errList = e.leased;
                errMsg = e.message;
              }
              this.showMessageNotification({type: 'error', description: errMsg, list: errList});
            });

            this.close();
        },
        close() {
            this.showConfirmation = false;
            this.$emit('close');
        },
    },
};
</script>

<style></style>
