<template>
    <div class="apw-plan-list pa-6">
        <div class="
        d-flex
        align-center
        justify-space-between
        apw-plan-list-header
        pb-2
        pr-1
      ">
            <span>Occupancy Threshold</span>
            <span>Advance {{ planType }} Window</span>
        </div>
        <v-divider></v-divider>
        <div class="apw-plan-content">
            <div v-for="(item, idx) in planSetting" :key="idx">
                <div class="d-flex align-center justify-space-between py-2 pr-1">
                    <span>{{ item.occupancy_percentage }}</span>
                    <span class="increase">
                        <span v-if="item.days == 0">
                            {{ item.days}} Days (No {{ planType === "Reservation" ? "Reservations" : "Advanced Rentals" }})
                        </span>
                        <span v-else>
                            {{ item.days}} Days
                        </span>
                    </span>
                </div>
                <v-divider></v-divider>
            </div>
        </div>
    </div>
</template>
<script>
import { cloneDeep } from "lodash";
export default {
    name: 'ApwPlanList',
    props: {
    setting: {
      type: Array,
      required: true,
      default: () => [],
    },
    planType: {
        type: String,
        required: true,
    }
  },
    computed: {
        planSetting(){
            let newSetting =  cloneDeep(this.setting);
            for (let index = 0; index < newSetting.length; index++) {
                if (index === 0) {
                    newSetting[index].occupancy_percentage = this.setting[index].occupancy_percentage + '% - 100%'; 
                } else {
                    newSetting[index].occupancy_percentage = this.setting[index].occupancy_percentage + '% - ' + this.setting[index - 1].occupancy_percentage + '%';
                }   
            }
            return newSetting
        },
    },
    data() {
        return{
            
        }
    },
    methods: {
    }
}
</script>
<style scoped>
.head-background-color {
    background-color: white;
}
.apw-plan-list {
  .apw-plan-list-header {
    color: #637381;
    font-size: 14px;
    font-weight: 400px;
  }
  .apw-plan-content {
    max-height: 200px;
    overflow: auto;
    .increase {
      color: #02ad0f;
    }
  }
}
</style>