export const ACCOUNTING = Object.freeze({
  FREQUENCY_LIST: [
    { text: "Daily", value: "daily" },
    { text: "Weekly", value: "weekly" },
    { text: "Every Two Weeks", value: "biweekly" },
    { text: "Monthly", value: "monthly" },
    { text: "Quarterly", value: "quarterly" },
  ],
  EXPORT_RANGE: [
    { text: "Yesterday", value: "yesterday" },
    { text: "Last 7 Days", value: "last_seven_days" },
    { text: "Last Calendar Month", value: "last_calendar_month" },
    { text: "Date Range", value: "date_range" },
    { text: "Date", value: "date" },
  ],
  WEEk_DAYS: [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ],
  ACCOUNTING_EXPORTS: {
    GENERAL_LEDGER:{
      KEY: "general_ledger",
      FORMATS: {
        PDF: {
          KEY: "pdf",
          LABEL: "General Ledger - PDF",
        },
      },
    },
    YARDI: {
      KEY: "yardi",
      FORMATS: {
        CSV: {
          KEY: "csv_yardi",
          LABEL: "Yardi - CSV",
        },
      }
    },
    YARDI_FINANCIAL_JOURNAL: {
      KEY: 'yardifinancialjournal',
      FORMATS: {
        CSV: {
          KEY: 'csv_yardifinancialjournal',
          LABEL: 'Yardi Financial Journal - CSV'  
        }
      }
    },
    YARDI_FINANCIAL_JOURNAL_IPP : {
      KEY: 'yardi-financial-journal-ipp',
      FORMATS: {
        CSV: {
          KEY: 'csv_yardi-financial-journal-ipp',
          LABEL: 'Yardi Financial Journal - IPP - CSV'   
        }
      }
    },
    SAGE_INTACCT: {
      KEY: "sageintacct",
      FORMATS: {
        CSV: {
          KEY: "csv_sageintacct",
          LABEL: "Sage Intacct - CSV",
        },
      },
    },
    ACCOUNTANT_SUMMARY: {
      KEY: "accountant_summary",
      FORMATS: {
        PDF: {
          KEY: "pdf_accountant_summary",
          LABEL: "Accountant Summary",
        },
      },
    },
    TRANSACTION_DETAILS: {
      KEY: "charges_detail",
      FORMATS: {
        CSV: {
          KEY: "csv_charges_detail",
          LABEL: "Transaction Details",
        },
      },
    },
    BALANCE_SHEET: {
      KEY: "balance_sheet",
      FORMATS: {
        PDF: {
          KEY: "pdf_balance_sheet",
          LABEL: "Balance Sheet - PDF",
        },
      },
    },
    QUICKBOOKS: {
      KEY: "quickbooks",
      FORMATS: {
        CSV: {
          KEY: "csv_quickbooks",
          LABEL: "Quickbooks Online - CSV",
        },
        IIF: {
          KEY: "iif_quickbooks",
          LABEL: "Quickbooks Desktop - IIF",
          IDENTIFIER: "iif",
        },
        IIF_CLASS_CODE: {
          KEY: "iifQuickbooks_class_code",
          LABEL: "Quickbooks Desktop - IIF with Class Codes",
          IDENTIFIER: "iifQuickbooks",
        },
      },
    },
    TRANSACTIONAL_JOURNAL: {
      KEY: "transactional_journal",
      FORMATS: {
        CSV: {
          KEY: "csv_charges_journal",
          LABEL: "Transaction Journal CSV",
        },
        PDF: {
          KEY: "pdf_charges_journal",
          LABEL: "Transaction Journal PDF",
        },
      },
    },
    APPFOLIO: {
       KEY: 'appfolio',
       FORMATS: {
         CSV: {
           KEY: 'csv_appfolio',
           LABEL: 'AppFolio Summarized - CSV'   
         }
       }
    },
    GREAT_PLAINS_EXPORTS: {
      KEY: 'great_plains_exports',
      FORMATS: {
        CSV_ZIP: {
          KEY: 'great_plains_exports',
          LABEL: 'KSS - Great Plains Exports'   
        }
      }
    },
    GREAT_PLAINS_RECEIPTS: {
      KEY: 'great-plains-receipt',
      FORMATS: {
        CSV: {
          KEY: 'csv_great-plains-receipt',
          LABEL: 'KSS - Great Plains Receipts - CSV'   
        }
      }
    },
    GREAT_PLAINS_REVENUE_HEADER: {
      KEY: 'great-plains-revenue-header',
      FORMATS: {
        CSV: {
          KEY: 'csv_great-plains-revenue-header',
          LABEL: 'KSS - Great Plains Revenue Header - CSV'   
        }
      }
    },
    GREAT_PLAINS_REVENUE_DETAILS: {
      KEY: 'great-plains-revenue-details',
      FORMATS: {
        CSV: {
          KEY: 'csv_great-plains-revenue-details',
          LABEL: 'KSS - Great Plains Revenue Detail - CSV'   
        }
      }
    },
 },
  FORMAT_LIST: [
    { text: "General Ledger - PDF", value: "pdf" },
    { text: "Quickbooks Online - CSV", value: "csv_quickbooks" },
    { text: "Quickbooks Desktop - IIF", value: "iif_quickbooks" },
    { text: 'Quickbooks Desktop - IIF with Class Codes', value: 'iifQuickbooks_class_code' },
    { text: "Yardi - CSV", value: "csv_yardi" },
    { text: "Sage Intacct - CSV", value: "csv_sageintacct" },
    {
      text: "Accountant Summary",
      value: "pdf_accountant_summary",
      disabled: false,
    },
    { text: "Transaction Details", value: "csv_charges_detail" },
    { text: "Balance Sheet - PDF", value: "pdf_balance_sheet" },
    { text: "Yardi Financial Journal - CSV", value: "csv_yardifinancialjournal" },
    { text: 'Yardi Financial Journal - IPP - CSV', value: 'csv_yardi-financial-journal-ipp' },
    { text: 'AppFolio Summarized - CSV', value: 'csv_appfolio' },
    {
      text: "Transaction Journal CSV",
      value: "csv_charges_journal",
      disabled: true,
    },
    {
      text: "Transaction Journal PDF",
      value: "pdf_charges_journal",
      disabled: true,
    },
  ],
  TYPE_LIST: [
    { text: "Summarized", value: "summarized" },
    { text: "Detailed", value: "detailed" },
  ],
  BOOK_TYPES: [
    { text: "Cash Book", value: 0 },
    { text: "Accrual Book", value: 1 },
    { text: "Double Book", value: 2 },
    { text: "GAAP Accrual Book", value: 3 },
  ],
  recordCashBookData: [
    {
      value: "both_books",
      name: "Yes",
    },
    {
      value: "cash_book_only",
      name: "No",
    },
  ],
  yardiExportRefColumn: [
    {
      value: "transaction",
      name: "Transaction Type",
    },
    {
      value: "management_software",
      name: "Management Software",
    },
  ],
  yardiExportTransactionNoFormat: [
    {
      value: "unique_each_transaction",
      name: "Unique for Each Transaction",
    },
    {
      value: "new_sequence_new_export",
      name: "New Sequence for New Export (starting from 1)",
    },
  ],
  appFolioCCAndACHConsolidated: [
    {
      value: "1",
      name: "Yes",
    },
    {
      value: "0",
      name: "No",
    },
  ],  
  requiredDefaultCOATypes: [
    "parking_rent" ,
    "storage_rent",
    "card_amex",
    "card_visa",
    "card_mastercard",
    "card_discover",
    "cash",
    "check",
    "ach_checking",
    "ach_savings",
  ],
});
