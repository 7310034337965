<template>
  <div class="report-options-align">
    <div v-if="template === 'review_rent_changes'">
      <div class="border-bottom">
        <p class="font-regular mt-4 px-5 hb-text-night-light">
          Please choose a specific date within the set date variance to generate accurate and relevant results. The date variance determines the range of dates around the selected date that will be considered for generating the results. By selecting a date within this range, you can ensure that the results align with your desired timeframe and provide the most up-to-date information.
        </p>
      </div>
      <div class="hb-cloud-lighter">
            <v-flex class="hb-flex">
              <div class="section-content pa-3">
                  <div class="primary-section-content">
                    <v-row class="mx-0">
                        <v-col cols="12" style="transition: 0.3s ease">
                            <v-row justify="center" class="ma-0">
                                <a v-for="(route, index) in reportItems" @click="sectionClicked(route)" class="settings-menu-item hb-larger-font-size font-weight-medium col-12 mb-2 pa-4" :key="index">
                                  {{route.title}} <span class="alignright"><v-icon>mdi-chevron-right</v-icon></span>
                                </a>
                            </v-row>
                        </v-col>
                      </v-row>
                  </div>
              </div>
            </v-flex>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ReportOptionsIndex",
  props: ['config','template'],
  data() {
    return{
      selected: '',
      reportItems: [
        { key: "CustomVariance", title: "Custom Variance", permission: null},
      ],
    }
  },
  methods:{

    takeAction(){
      this.$emit('takeAction', this.selected)
    },
    //added by BCT
    sectionClicked(selected) {
      this.selected = selected;
      if (selected.permission && !this.hasPermission(selected.permission)) {
        this.permissionError = true;
        return;
      }
      this.$emit('takeAction', selected.key, selected)

    }

  }
}
</script>
<style lang="scss" scoped>
  .settings-menu-item{
    text-decoration: none;
    line-height: 24px;
    background-color: #FFFFFF;
    color: #101318;
    border-radius: 4px;
    border: 1px solid #c4cdd5;
  }
  .alignright {
    float: right;
  }
  .report-options-align {
    position: absolute;
    top:0px;
    bottom: 64px;
    width: 100%;
  }
</style>