<script type="text/babel">
import HbStatus from "../../aviary/HbStatus.vue";
import HbStatusGeneral from "../../aviary/HbStatusGeneral.vue";
import statuses from "../../constants/status";

export default {
  components: { HbStatus, HbStatusGeneral },
  data() {
    return {
      leaseStatusColors: [
        { color: "#02AD0F", name: "Active", description: "Lease is Active." },
        {
          color: "#FB4C4C",
          name: "Inactive",
          description: "Lease is Inactive.",
        },
        { color: "#919EAB", name: "Exempt", description: "National Account is Exempt." },
      ],
      national_account: [
        { color: "#02AD0F", name: "Active", description: "Lease is Active." },
        {
          color: "#919EAB",
          name: "Inactive",
          description: "National Account is Inactive.",
        }
      ],
    };
  },
  computed: {
    statusColor() {
      let statusColors = this.params?.leaseStatus
        ? this.leaseStatusColors : this.params.column.colId=="na_status" ? this.national_account 
        : statuses;
      let status = statusColors.find(
        (i) =>
          String(this.params.valueFormatted).toLowerCase() ===
          i.name.toLowerCase()
      );
      return status?.color ?? "#919EAB";
    },
    getStatus() {
      let value = String(this.params.valueFormatted).toLowerCase();
      switch (value) {
        // Built-in cases where custom icon is not needed
        case "error":
        case "cancelled":
          return {
            type: "error", //-----RED
            icon: null,
          };
        case "caution":
        case "skipped":
        case "pending":
          return {
            type: "caution", //---YELLOW
            icon: null,
          };
        case "approved":
        case "auto approved":
          return {
            type: "success", //---GREEN
            icon: null,
          };
        case "deployed":
          return {
            type: "guidance", //--BLUE
            icon: null,
          };

        // Custom cases where custom icon is needed
        case "active":
          return {
            type: "success", //---GREEN
            icon: "mdi-check-circle",
          };
        case "to approve":
          return {
            type: "default", //---GREY
            icon: "mdi-minus-circle",
          };

        default:
          return {
            type: "default", //---GREY
            icon: null,
          };
      }
    },
  },
};
</script>

<template>
  <div>
    <template v-if="params.valueFormatted">
      <HbStatusGeneral
        class="mt-1"
        v-if="params.general"
        :type="getStatus.type"
        :status="params.valueFormatted"
        :icon="getStatus.icon"
      />
      <hb-status v-else :color="statusColor" class="override-overflow">
        {{ params.valueFormatted }}
      </hb-status>
    </template>
  </div>
</template>
