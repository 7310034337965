<template>
    <hb-modal show-help-link v-model="dialog" size="medium" title="Send Space Information" @close="$emit('close')">
        <template v-slot:content>
        <v-container class="ma-0 pa-0">
          <div>
            <div class="interaction">
              <div class="interaction-details">
                <div class="slide-out-section-label">
                  <span class="font-weight-medium">Send to</span>
                </div>
              </div>
              <div class="interaction-text">
                <span>
                  <div class="form-row">
                    <div class="form-input-container">
                      <div class="checkbox">
                        <input
                          type="checkbox"
                          id="checkbox-contact"
                          name="checkbox-contact"
                          data-name="checkbox-contact"
                          class="w-checkbox-input" 
                        />
 
                        <label for="checkbox-contact" class="w-form-label">
                          <input
                            placeholder="Select Recent Contact"
                            type="text"
                            id="checkbox-contact"
                            name="checkbox-contact"
                            data-name="checkbox-contact"
                            class="w-checkbox-input"
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-input-container">
                      <div class="checkbox">
                        <input
                          type="checkbox"
                          id="checkbox-phone"
                          name="checkbox-phone"
                          data-name="checkbox-phone"
                          class="w-checkbox-input"
                        />

                        <label for="checkbox-phone" class="w-form-label"><input
                            placeholder="Enter Phone Number"
                            type="text"
                            id="checkbox-contact"
                            name="checkbox-contact"
                            data-name="checkbox-contact"
                            class="w-checkbox-input"
                          /></label>
                      </div>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-input-container">
                      <div class="checkbox">
                        <input
                          type="checkbox"
                          id="checkbox-email"
                          name="checkbox-email"
                          data-name="checkbox-email"
                          class="w-checkbox-input"
                        />

                        <label for="checkbox-email" class="w-form-label"><input
                            placeholder="Enter Email Address"
                            type="text"
                            id="checkbox-contact"
                            name="checkbox-contact"
                            data-name="checkbox-contact"
                            class="w-checkbox-input"
                          /></label>
                      </div>
                    </div>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </v-container>
        </template>
        <template v-slot:actions>
            <hb-btn color="primary">Send</hb-btn>
        </template>
      </hb-modal>
</template>

<script type="text/babel">
export default {
  name: "SendSpaceInfo",
  data() {
    return {};
  },
  computed: {
    dialog: {
      get () {
          return this.value
      },
      set (value) {
          this.$emit('input', value)
      }
    },
  },
  props:['value'],
};
</script>

<style lang="scss" scoped>
.interaction {
  border-bottom: none;
}
.interaction-details {
    background-color: #f0f0f5;
}
.w-checkbox-input {
  font-size: 14px;
}
</style>