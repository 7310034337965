<template>
  <div style="position: absolute; top: 0; bottom: 64px; width: 100%">
    <v-card flat class="edit-panel" style="height: 100%; overflow-y: auto">
      <div>
        <div class="border-bottom hb-text-night-light py-2 px-2 pl-4">
          Modify the current rent amount for the user.
           Adjusting the rent amount may result in changes to the scheduled timeframe for deployment.
        </div>
        <RentChangeForm
          :ids="ids"
          :bulk="true"
          ref="rentChangeFormRef"
          :is-rent-change="hasRentChangeType"
          @validForm="onValidForm"
          @cancel="$emit('cancel')"
          @loading="loading = $event"
        />
      </div>
    </v-card>

    <hb-bottom-action-bar @close="$emit('cancel')" class="bottom-bar-height">
      <template v-slot:right-actions>
        <hb-btn
          :loading="loading"
          color="primary"
          @click="applyManualRentChange"
          :disabled="!enableManualRentChange"
        >
          Save
        </hb-btn>
      </template>
    </hb-bottom-action-bar>
  </div>
</template>

<script>
import RentChangeForm from "../../revenue_management/rent_management/RentChangeForm.vue";
import ConfirmationPopup from "@/components/revenue_management/utils/ConfirmationPopup.vue";
import { mapActions, mapGetters } from "vuex";
import api from "../../../assets/api";
import { EventBus } from "../../../EventBus.js";
import Loader from "../../assets/CircleSpinner.vue";
import { notificationMixin } from "@/mixins/notificationMixin.js";

export default {
  name: "BulkManualRentChange",
  components: {
    Loader,
    RentChangeForm,
    ConfirmationPopup,
  },
  data() {
    return {
      loading: false,
      amenityList: {},
      updatedAmenities: [],
      enableManualRentChange: false,
      manualRentChangeData: {},
    };
  },
  props: ["items", "selected"],
  mixins: [notificationMixin],
  computed: {
    ...mapGetters({
      properties: "propertiesStore/filtered",
      bulkEditProperty: "propertiesStore/bulkEditProperty",
    }),
    hasRentChangeType() {
      return this.selected.type === "rent-change";
    },
    ids() {
      return this.items.map((item) => item.lease_id);
    },
  },

  async created() {},

  methods: {
    ...mapActions({}),

    onValidForm(value) {
      this.enableManualRentChange = value;
    },

    clearManualRentModal() {
      this.$refs.rentChangeFormRef.clearManualRentModal();
    },

    async applyManualRentChange() {
      this.$refs.rentChangeFormRef.applyManualRentChange(true);
    },
  },
  watch: {
    items: {
      deep: true,
      immediate: true,
      handler(value) {
        if (!value.length) this.$emit("cancel");
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.bottom-bar-height {
  ::v-deep .hb-bab-wrapper {
    height: 63px !important;
  }
}
</style>
