<template>
  <div style="position: absolute; top:0; bottom: 64px; width: 100%; ">
    <v-card flat class="edit-panel" style="height: 100%; overflow-y: auto;">
      <div>
        <div class="border-bottom hb-text-night-light py-2 px-2 pl-4">
          Exempt the selected tenants from future rent changes.
        </div>
        <hb-form 
          label="Exempt from Rent Change" 
          class="next-rent-change" 
          full
        >
          <hb-checkbox v-model="rentExempt" label="Exempt selected tenants from future rent changes."/>
          <span class="reason-text hb-text-night-light hb-font-caption">
              Notes:
          </span>
          <HbTextarea
            v-model.trim="reason"
            placeholder="Enter Reason"
            v-validate="'max:255'"
            data-vv-name="reason"
            data-vv-as="Reason"
            :error="errors.collect('name').length > 0"
          />
        </hb-form>
      </div>
    </v-card>
    <hb-bottom-action-bar @close="$emit('cancel')" class="bottom-bar-height">
      <template v-slot:right-actions>
        <hb-btn color="primary" @click="exemptRentChange">
          Save
        </hb-btn>
      </template>
    </hb-bottom-action-bar>
  </div>
</template>

<script>
import RentChangeForm from "../../revenue_management/rent_management/RentChangeForm.vue";
import ConfirmationPopup from "@/components/revenue_management/utils/ConfirmationPopup.vue";
import { mapActions, mapGetters } from "vuex";
import api from '../../../assets/api';
import { EventBus } from '../../../EventBus.js';
import Loader from '../../assets/CircleSpinner.vue';
import { notificationMixin } from  '@/mixins/notificationMixin.js';

export default {
    name: "BulkExemptRentChange",
    components:{
      Loader,
      RentChangeForm,
      ConfirmationPopup
    },
    data() {
      return {
        rentExempt: false,
        reason: '',
        enableManualRentChange: false,
        manualRentChangeData: {},
      }
    },
    props: ['items'],
    mixins: [notificationMixin],
    computed: {
      ...mapGetters({
        properties: "propertiesStore/filtered",
        bulkEditProperty: "propertiesStore/bulkEditProperty",
      }),
    },
    async created() {
    },
    methods: {
      ...mapActions({}),

    // isDirty(value) {
    //   return this.rentExempt
    // },


    async exemptRentChange() {
       const success = await this.$validator.validateAll();
       if(!success) return;
       let body = {
        lease_ids: this.items.map(item => item.lease_id),
        status: this.rentExempt ? 'exempt' : 'active',
        note: this.reason,
       }
      let url =
        api.getPropertyRentManagementUrl(this.bulkEditProperty) + "leases";
      await api
        .put(this, url, body)
        .then(() => {
          this.showMessageNotification({
            type: "success",
            description: `You have ${this.rentExempt ? 'exempted' : 'reinstated'} the rent changes for the selected tenants.`,
            timeout: 3000,
          });
          EventBus.$emit("tenantRentManagementEvent", { event: 'refreshTable' })
          EventBus.$emit("rentChangeQueueEvents", "refreshTable");
          this.$emit('cancel')
        })
        .catch((error) => {
          this.showMessageNotification({
            type: "error",
            description: 'There are errors on your form. Please review the form and correct any errors before proceeding.',
          });
        });
    },
  },
  watch: {
    items: {
      deep: true,
      immediate: true,
      handler(value) {
        if(!value.length) this.$emit("cancel");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  .bottom-bar-height {
    ::v-deep .hb-bab-wrapper {
      height: 63px !important;
    }
  }
</style>
