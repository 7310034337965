<template>
    <hb-modal
        size="large"
        title="Send A Message"
        v-model="dialog"
        @close="$emit('close')"
        show-help-link
    >
        <template v-slot:content>

            <v-container class="pt-3 px-10">
                <v-row>
                    <v-col>
                        <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
                        <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)" :message="successGet($options.name)" status="success"></status>
                    </v-col>
                </v-row>
                    <div v-if="contact && (contact.email || sms_phones.length > 0)">
            <!--            <div class="form-row" v-if="contact && contact.email">-->
            <!--                <div class="form-label">-->
            <!--                    <label>-->
            <!--                        TO: {{contact.first}} {{contact.last}} < {{contact.email}} >-->
            <!--                    </label>-->
            <!--                </div>-->
            <!--            </div>-->

            <!--        <div class="form-row" v-if="contacts && contacts.length">-->
            <!--          <div class="form-label">-->
            <!--            <label>-->
            <!--              SENDING TO:  {{ contacts.length }} Recipients-->
            <!--            </label>-->
            <!--          </div>-->
            <!--        </div>-->


                        <div class="form-row" >
                            <div class="form-label"><label>Subject</label></div>
                            <div class="form-input-container" :class="{'has-error': errors.has('subject') }">
                                <input type="text" class="w-input" v-model="form.subject" name="subject" v-validate="'required|max:78'" />
                            </div>
                            <span v-show="errors.has('subject')" class="status-block error-block field-error">{{ errors.first('subject') }}</span>
                        </div>

                        <div class="form-row">
                            <div class="form-label">
                                <label>Message</label>
                            </div>
                            <div class="form-input-container" :class="{'has-error': errors.has('message') }">
                                <textarea class="w-input" name="message" v-model="form.message" v-validate="'required|max:384000'" rows="8" />
                                <span v-show="errors.has('message')" class="status-block error-block field-error">{{ errors.first('message') }}</span>
                            </div>
                        </div>
                        <div class="form-row pt-4">
                            <div class="form-input-container" v-if="contact.email">
                                <v-checkbox
                                    class="mr-2 mt-0 pt-0" 
                                    v-model="form.send_email"
                                    id="checkbox-email"
                                    name="checkbox-email"
                                    data-name="checkbox-email"
                                >
                                    <template v-slot:label>
                                        Send email to  {{contact.email}}
                                    </template>
                                </v-checkbox>

                            </div>
                            <div class="form-input-container" v-for="p in contact.Phones" v-show="p.sms" :key="p.id" >
                                <v-checkbox
                                    class="mr-2 mt-0 pt-0"
                                    v-model="form.sms"
                                    :value="p.id"
                                    :id="'checkbox-phone' + p.id"
                                    :name="'checkbox-phone' + p.id"
                                    :data-name="'checkbox-phone' + p.id"
                                >
                                    <template v-slot:label>
                                        Send SMS Message to  {{p.phone | formatPhone}} ({{p.type}})
                                    </template>
                                </v-checkbox>
                            </div>
                        </div>
                        <!--
                        <div class="form-row">
                            <div class="form-label">
                                <label>&nbsp;</label>
                            </div>
                            <div class="form-input-container text-right mt-6">
                                <v-btn class="text-capitalize mr-1" color="secondary" @click="$emit('close')">Cancel</v-btn> &nbsp;&nbsp;
                                <v-btn class="text-capitalize" color="primary" :disabled="isLoading($options.name)" @click="sendMessage">Send Message</v-btn>
                                <loader v-show="isLoading($options.name)" color="#00b2ce" size="20px" class="inline-loader"></loader>
                            </div>
                        </div>
                        -->

                    </div>
                
                    <v-row v-else-if="dataLoading" >
                        <v-alert type="error">
                            There is no way to contact this person.  Please add either an email address or a phone number capable of receiving sms messages.
                        </v-alert>
                    </v-row>
            </v-container>

        </template>
        <template v-slot:actions>
            <hb-btn color="primary" @click="sendMessage">Send Message</hb-btn>
            <loader v-show="isLoading($options.name)" color="#00b2ce" size="20px" class="inline-loader"></loader>
        </template>
    </hb-modal>
</template>


<script type="text/babel">
    import api from '../../assets/api.js';
    import Status from '../includes/Messages.vue';
    import Loader from '../assets/CircleSpinner.vue';
    import { EventBus } from '../../EventBus.js';
    export default {
        name: "SendMessage",
        data() {
            return {
                contact_details: [],
                showWindow: false,
                form:{
                    message: '',
                    subject: '',
                    send_email: true,
                    sms: []
                },
                dataLoading:true
            }
        },
        props: ['contact', 'show', 'contacts', 'value'],
        components:{
            Loader,
            Status
        },
        computed:{
            dialog: {
                get () {
                    return this.value
                },
                set (value) {
                    this.$emit('input', value)
                }
            },
          sms_phones(){
              if(!this.contact.Phones) return false;
              return this.contact.Phones.filter(p => p.sms);
          }
        },
        watch: {
            contact () {
                this.getContactDetails(this.contact)
            }
        },
        filters: {
            formatDate(date){
                return moment(date).format('MM/DD/YYYY');

            }
        },
        created(){
            this.getContactDetails(this.contact)
            // if(this.contacts){
            //     this.getContacts();
            // }
        },
        methods:{
            async getContacts(){
                let r = await api.post(this, api.CONTACTS + 'search', {
                  contact_ids: this.contacts
                }).then(r => {
                    this.contact_details = r.contacts
                })
            },
            getContactDetails(contact_id) {
                if (typeof contact_id === 'string') {
                    this.dataLoading = false
                    api.get(this, api.CONTACTS + contact_id).then(x=> {
                        this.contact = x.contact
                        this.dataLoading = true
                    }).catch(err => {
                        this.dataLoading = true
                    })
                }
            },
            sendMessage(){
                var data = this.form;

                this.validate(this).then(status => {
                    if(!status) return;
                    return api.post(this, api.CONTACTS + this.contact.id + '/send-message', data).then(r => {
                        this.form = {
                            message: '',
                            subject: '',
                            send_email: true,
                            sms: []
                        }
                        this.successSet(this.$options.name, "Message sent!")
                    })
                })
            }
        },
    }
</script>
