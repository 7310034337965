<template>
  <plan-card-layout :tabs="tabsData" :apiLoading="apiLoading" :isSelected="isSelected">
    <template v-if="plan.tenant_default == '1'" slot="title-icons">
        <HbIcon class="mt-1 mr-2" color="#38AFC6" mdi-code="mdi-custom-tenant-logo" />
    </template>
    <template slot="title">
      {{ plan.name }}
    </template>
    <template v-if="plan?.instore_default == '1' || plan?.online_default == '1'" slot="default-icon">
      <HbStatusGeneral
          type="guidance"
          :status="planDefault"
      />
    </template>
    <template slot="description">
      <span>
        {{plan.description}}
      </span>
    </template>
    <template slot="sub-heading">
      <v-divider></v-divider>
      <div class="hb-text-light pt-4 px-6">
        The occupancy tiers will determine the advanced rental/reservation window in days for any given space groups.
      </div>
    </template>
    <template v-for="tab in tabsData">
      <template :slot="tab.key">
        <component :is="tab.componentName" v-bind="tab.props" :key="tab.key" />
      </template>
    </template>
    <template v-if="options" slot="option">
      <hb-menu options>
        <v-list>
          <v-list-item v-if="plan?.tenant_default != '1'" @click="showCreatePlan = true" :ripple="false">
            <v-list-item-title>Edit</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="plan?.instore_default != '1'" @click="defaultInstoreModel = true" :ripple="false">
            <v-list-item-title>Set as In-Store Default</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="plan?.online_default != '1'" @click="defaultOnlineModel = true" :ripple="false">
            <v-list-item-title>Set as Online Default</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="plan?.tenant_default != '1' && plan?.instore_default != '1' && plan?.online_default != '1'"
            @click="deleteModel = true" :ripple="false">
            <v-list-item-title>Delete</v-list-item-title>
          </v-list-item>
        </v-list>
      </hb-menu>
    </template>
    <template slot="form">
      <apw-create-plan v-if="showCreatePlan" v-model="showCreatePlan" :planDetails="plan" @plan-save-success="$emit('plan-changed')"></apw-create-plan>
      <confirmation-pop-up v-model="deleteModel" type="delete" message="Are you sure you want to delete the selected plan?" title="Delete Plan" @delete-plan="deletePlan"></confirmation-pop-up>
      <confirmation-pop-up v-model="defaultOnlineModel" type="defaultOnline" message="Be advised that if you select a new default Advanced Reservation and Rental plan, then any groups currently using your default plan will change over to your new selection." title="Set Online Default Plan" @online-default-plan=" setPlanToDefault('online')"></confirmation-pop-up>
      <confirmation-pop-up v-model="defaultInstoreModel" type="defaultInstore" message="Be advised that if you select a new default Advanced Reservation and Rental plan, then any groups currently using your default plan will change over to your new selection." title="Set Instore Default Plan" @instore-default-plan=" setPlanToDefault('instore')"></confirmation-pop-up>
    </template>
  </plan-card-layout>
</template>
<script>
import { notificationMixin } from "@/mixins/notificationMixin.js";
import PlanCardLayout from "../revenue_management/utils/PlanCardLayout.vue";
import ApwPlanList from "./ApwPlanList.vue";
import ApwCreatePlan from "./ApwCreatePlan.vue";
import api from "../../assets/api";
import ConfirmationPopUp from "./ConfirmationPopUp.vue";


export default {
  name: "ApwCard",
  mixins: [notificationMixin],
  props: ['isSelected', 'plan', 'options'],
  computed: {
    tabsData() {
      return [
        {
          label: "Reservation",
          key: "reservation",
          componentName: "ApwPlanList",
          props: {setting: this.plan.reservation_settings, planType: 'Reservation'}

        },
        {
          label: "Rental",
          key: "rental",
          componentName: "ApwPlanList",
          props: {setting: this.plan.rental_settings, planType: 'Rental'}

        }
      ]
    },
    planDefault() {
      if(this.plan.online_default == '1' && this.plan.instore_default == '1') {
        return 'In-Store and Online Default'
      }else if(this.plan.online_default == '1'){
        return 'Online Default'
      }else if(this.plan.instore_default == '1'){
        return 'In-Store Default'
      }
    },
  },
  components: {
    PlanCardLayout,
    ApwPlanList,
    ApwCreatePlan,
    ConfirmationPopUp
  },
  data() {
    return {
      apiLoading: false,
      confirmationType: "delete",
      tab: 0,
      showCreatePlan: false,
      deleteModel:false,
      defaultOnlineModel: false,
      defaultInstoreModel: false,
    };
  },
  methods: {
    async deletePlan() {
      this.deleteModel = false;
      try {
        await api.delete(this, api.APW_MANAGEMENT + 'plans/' + this.plan.id);
        this.showMessageNotification({ type: 'success', description: 'Plan deleted successfully.' });
        this.$emit('plan-changed');
      } catch (error) {
        if(error.includes('ER_ROW_IS_REFERENCED_2')){
          this.showMessageNotification({ type: 'error', description: 'This plan is assigned to one or more space groups, please unassign before performing this action' });
        }else{
          this.showMessageNotification({ type: 'error', description: error });
        }

      }
    },
    async setPlanToDefault(type) {
      try {
        let data = {
          type: type
        };
        await api.put(this, api.APW_MANAGEMENT + 'plans/' + this.plan.id + '/default', data);
        this.showMessageNotification({ type:'success', description: 'Plan set as default successfully.' });
        this.$emit('plan-changed');
        this.defaultInstoreModel = false;
        this.defaultOnlineModel = false;
      } catch (error) {
        this.apiLoading = false;
        this.showErrorNotification({type:'error', description: 'Found some error'});
      }
    }
  },
};
</script>
<style scoped></style>