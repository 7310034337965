<template>
  <div class="section-content pt-4" :class="{'mt-10' : $vuetify.breakpoint.xs}" v-resize="getHeights">
    <div ref="rmTopSection">
      <hb-header full :divider="false">
        <hb-page-header
          title="Accounting Export"
          description="Manage the scheduling details of your exports. View your export history. "
        >
        </hb-page-header>
      </hb-header>
    </div>
      <div>
        <div ref="rmTabSection">
          <hb-tabs v-model="activeTab">
              <v-tab
                v-for="item in tabOptions"
                :key="item.key"
                :href="'#tab-' + item.key"
                @click="view = item.key"
                :ripple="false"
              >
                {{ item.label }}
              </v-tab>
            </hb-tabs>
          <v-divider></v-divider>
        </div>

    <exports v-if="view === 'exports'" class="tab-item pr-4 pb-8"   :key="report_key"></exports>
    <history v-if="view === 'history'" class="tab-item pr-4" :style="'height:' + listMaxHeight + 'px'"   :key="report_key"></history>
    </div>
  </div>
</template>

<script type="text/babel">

import Exports from './Exports.vue'
import History from './History.vue'
import { EventBus } from "../../EventBus.js";

export default {
  data () {
    return {
      view: "exports",
      tabOptions:[
        { label: 'Exports', key: 'exports' },
        { label: 'History', key: 'history' }
      ],
      activeTab: null,
      listMaxHeight: '',
      topContentHeight: '',
      tabContentHeight: '',
      pageHeight: '',
      offsetTopHeight: '',
      report_key: 0,
    }
  },
  components:{
    Exports,
    History
  },
  mounted(){
    this.getHeights();
  },
  computed:{
    
  },
  created(){
    EventBus.$on('refetch_data', () => this.fetchData());
  },
  destroyed(){
    EventBus.$off('refetch_data', () => this.fetchData());
  },
  methods:{
    getHeights(){
      if(this.$vuetify.breakpoint.xs){
        this.offsetTopHeight = 130;
      } else {
        this.offsetTopHeight = 90;
      }
      this.topContentHeight = this.$refs.rmTopSection.clientHeight;
      this.tabContentHeight = this.$refs.rmTabSection.clientHeight;
      this.pageHeight = document.documentElement.clientHeight;
      this.listMaxHeight = this.pageHeight - this.topContentHeight - this.tabContentHeight - this.offsetTopHeight;
    },
    fetchData(){
      this.report_key++;
    }
  }
}
</script>

<style scoped lang="scss">
  .tab-bar {
    color:#101318;
    text-transform: none;
    background: #F9FAFB;
  }
  .tab-item {
    background: #F9FAFB;
    height: 100%;
    width: 100%;
  }
  .subHeading {
    font-size: 14px;
    vertical-align: top;
    letter-spacing: -0.2px;
    align-content: left;
    margin: 0px;
  }

  .section-content {
    padding-right: 0px !important;
  }

</style>

